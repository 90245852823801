import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "addTokenModal",
    "sectionSelector",
    "tokenSection",
    "poapSection",
    "updateGuideline",
    "addSocialModal",
    "updatePlatformAvatar",
    "gatingMethodsInfoModal",
  ];

  updateChatAnimation() {
    let element = event.currentTarget;
    let chatAnimationData = element.dataset;
    let url = `/accounts/${chatAnimationData.accountId}/account_chat_animations`;
    //delete it and remove selection
    if (chatAnimationData.accountChatAnimationId.length == 0) {
      let reqData = {
        account_chat_animation: {
          chat_animation_id: chatAnimationData.chatAnimationId,
        },
      };
      $.ajax({
        url: url,
        type: "POST",
        data: reqData,
      }).then(function (data) {
        chatAnimationData.accountChatAnimationId = data.id;
        $(element).addClass("sticker-selected");
      });
    } else {
      //add new chat animation to account
      $.ajax({
        type: "DELETE",
        url: `${url}/${chatAnimationData.accountChatAnimationId}`,
      }).then(function (data) {
        chatAnimationData.accountChatAnimationId = "";
        $(element).removeClass("sticker-selected");
      });
    }
  }

  openPopUp(target) {
    event.preventDefault();
    let modalController = this.application.getControllerForElementAndIdentifier(
      target,
      "modal"
    );
    modalController.open();
  }

  addToken() {
    $(this.addTokenModalTarget)
      .find(".setting-title")
      .text("Create a New Gate");
    this.clearForm();
    this.openPopUp(this.addTokenModalTarget);
  }

  editToken() {
    this.clearForm();
    $(this.addTokenModalTarget)
      .find(".setting-title")
      .text("Edit a Token Gate");
    let data = event.currentTarget.dataset;
    $(this.addTokenModalTarget).find("#token_id").val(data.tokenId);
    $(this.addTokenModalTarget).find(".token-name").val(data.tokenName);
    this.sectionSelectorTarget.value = data.tokenType;
    if (data.tokenType == "poap") {
      $(this.poapSectionTarget)
        .find("input")
        .val(JSON.parse(data.tokenEventId)[0]);
    } else {
      $(this.tokenSectionTarget).find("select").val(data.tokenChain);
      $(this.tokenSectionTarget).find("input").val(data.tokenAddress);
    }
    this.switchSection();
    this.openPopUp(this.addTokenModalTarget);
  }

  clearForm() {
    $(this.addTokenModalTarget).find("#token_id").val("");
    $(this.addTokenModalTarget).find(".token-name").val("");
    $(this.poapSectionTarget).find("input").val("");
    $(this.tokenSectionTarget).find("select").val("");
    $(this.tokenSectionTarget).find("input").val("");
  }

  switchSection() {
    let selectedSection = this.sectionSelectorTarget.value;

    let target = this.tokenSectionTarget;
    let hiddenSection = this.poapSectionTarget;
    if (selectedSection == "poap") {
      target = this.poapSectionTarget;
      hiddenSection = this.tokenSectionTarget;
      hiddenSection.querySelector("select").removeAttribute("required");
    } else {
      target.querySelector("select").setAttribute("required", "required");
    }

    target.style.display = "";
    hiddenSection.style.display = "none";
    hiddenSection.querySelector("input").removeAttribute("required");
    target.querySelector("input").setAttribute("required", "required");
  }

  openGuidelineModal() {
    this.openPopUp(this.updateGuidelineTarget);
  }

  clearSocialLinkForm(selectDefault) {
    $(this.addSocialModalTarget).find("#social_link_id").val("");
    $(this.addSocialModalTarget).find(".social-link-name").val("");
    $(this.addSocialModalTarget).find(".social-link-url").val("");
    $(this.addSocialModalTarget)
      .find(".social-icon-section .social-icon")
      .removeClass("selected");
    if (selectDefault) {
      let selectedIconName = $(this.addSocialModalTarget)
        .find(".social-icon-section .social-icon")
        .data("name");
      $("#icon_name_id").val(selectedIconName);
      $(this.addSocialModalTarget)
        .find(".social-icon-section .social-icon:first")
        .addClass("selected");
    }
  }

  addSocialMediaChannel() {
    $(this.addSocialModalTarget).find(".setting-title").text("Add Links");
    this.clearSocialLinkForm(true);
    this.openPopUp(this.addSocialModalTarget);
  }

  editSocialMediaChannel() {
    this.clearSocialLinkForm(false);
    $(this.addSocialModalTarget).find(".setting-title").text("Edit Links");
    let data = event.currentTarget.dataset;
    $(this.addSocialModalTarget).find("#social_link_id").val(data.socialLinkId);
    $(this.addSocialModalTarget)
      .find(".social-link-name")
      .val(data.socialLinkName);
    $(this.addSocialModalTarget)
      .find(".social-link-url")
      .val(data.socialLinkUrl);
    $(this.addSocialModalTarget).find("#icon_name_id").val(data.socialLinkIcon);
    $(this.addSocialModalTarget)
      .find(
        `.social-icon-section .social-icon[data-name='${data.socialLinkIcon}']`
      )
      .addClass("selected");
    this.openPopUp(this.addSocialModalTarget);
  }

  clickSocialIcon() {
    $(this.addSocialModalTarget)
      .find(".social-icon-section .social-icon")
      .removeClass("selected");
    $("#icon_name_id").val(event.currentTarget.dataset.name);
    $(event.currentTarget).addClass("selected");
  }

  openAvatarModal() {
    this.openPopUp(this.updatePlatformAvatarTarget);
  }

  showGatingMethodInfo() {
    this.openPopUp(this.gatingMethodsInfoModalTarget);
  }
}
