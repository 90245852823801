import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["uploadInput"];
  connect() {
    this.initFileUploadControls();
  }

  initFileUploadControls() {
    this.uploadInputTarget.addEventListener("direct-upload:start", (event) => {
      const meter = event.currentTarget.parentNode.querySelector(".meter");
      const sizeLabel =
        event.currentTarget.parentNode.querySelector(".field-box-span");
      meter.style.display = "block";
      event.currentTarget.style.display = "none";
      sizeLabel.style.display = "none";
    });

    this.uploadInputTarget.addEventListener(
      "direct-upload:progress",
      (event) => {
        const { id, progress, file } = event.detail;
        const meterWidth = event.currentTarget.parentNode.querySelector(
          ".meter > .meter-width"
        );
        meterWidth.style.width = `${progress}%`;
      }
    );

    this.uploadInputTarget.addEventListener("direct-upload:end", (_event) => {
      console.log("upload completed");
    });
  }

  imageSave() {
    Rails.fire(event.currentTarget.form, "submit");
  }
}
