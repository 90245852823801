import { Controller } from '@hotwired/stimulus';
import Pickr from '@simonwep/pickr';
import Rails from "@rails/ujs";
import Cookies from 'js-cookie';

export default class extends Controller {
  connect() {
    window.elementResized = function(height, width) {
      $("#element_height").val(height.toString());
      $("#element_width").val(width.toString());
    }
    var _this = this;
    var sidebarSizeCookie = Cookies.get('sidebarSize');
    if(sidebarSizeCookie && document.getElementById('elements-list')) {
      sidebarSizeCookie = JSON.parse(sidebarSizeCookie);
      $('.pages-section').height(sidebarSizeCookie['pages-section']['height']);
      $('.elements-section').height(sidebarSizeCookie['elements-section']['height']);
      $('.elements-section').css({ top: sidebarSizeCookie['elements-section']['top'] });
      _this.sizeUpIframe();
    }
    if(Turbolinks.savedScrolls) {
      if(Turbolinks.savedScrolls['layers-list']) {
        document.getElementById('layers-list').scrollTop = Turbolinks.savedScrolls['layers-list']
      }
      if(Turbolinks.savedScrolls['elements-list']) {
        document.getElementById('elements-list').scrollTop = Turbolinks.savedScrolls['elements-list']
      }
      Turbolinks.savedScrolls = null;
    }
    $('.elements-section').resizable({
      handles: "n, s",
      containment: "parent",
      minHeight: 125,
      resize: function(event, ui) {
        $('.elements-section').resizable("option", "maxHeight", (document.body.offsetHeight - 185));
        $('.pages-section').height(document.body.offsetHeight - ui.size.height - 62);
      },
      stop: function(event, ui) {
        Cookies.set('sidebarSize', JSON.stringify({
          'pages-section': {
            height: $('.pages-section').height()
          },
          'elements-section': {
            height: $('.elements-section').height(),
            top: $('.elements-section').position().top
          }
        }));
      }
    });
    $('.element-items .btn-publish').on('ajax:success', function(){
      if($(this).hasClass('make-invisible')) {
        $(this).removeClass('active');
        $(this).parent().find('.make-visible').addClass('active');
      } else if($(this).hasClass('make-visible')) {
        $(this).removeClass('active');
        $(this).parent().find('.make-invisible').addClass('active');
      }
    })
    $('.layers-list .btn-settings').click(function(){
      var url = $(this).attr('href');
      if($(this).parent().parent().parent().hasClass('active')) {
        $('.element-container').remove();
        $('.layer-container').addClass('element-open');
        $('.layer-container').prepend('<div class="element-container"><div class="loading">Loading ...</div></div>');
        $('.element-items li').removeClass('active');
        // $('.layers-list li').removeClass('active');
        // $(this).parent().parent().parent().addClass('active');
        history.pushState(null, '', url);
        $.ajax({
          url: url + '?partial'
        }).done(function(data){
          $('.element-container').remove();
          $('.layer-container').prepend(data);
          _this.sizeUpIframe();
          _this.bindColorPicker();
        })
        return false;
      }
    })
    _this.sizeUpIframe();
    window.addEventListener('resize', _this.sizeUpIframe);
    _this.bindColorPicker();
    $('#layer_background_image').on('direct-upload:start', (event) => {
      const { id, file } = event.detail
      const meter = $(event.currentTarget).parent().parent().find('.attachment-info .meter')
      meter.css('display', 'block')
    });
    $('#layer_background_image').on('direct-upload:progress', (event) => {
      const { id, progress, file } = event.detail
      const meter = $(event.currentTarget).parent().parent().find('.attachment-info .meter')
      meter.find('.meter-width').css('width', progress + '%')
    });
    $('#layer_background_image').on('direct-upload:end', (event) => {
      const { id, file } = event.detail
      const meter = $(event.currentTarget).parent().parent().find('.attachment-info .meter')
      $('#reload').val('yes');
    });
  }

  sectionToggle() {
    event.preventDefault()
    var sectionElement = $(event.currentTarget).parent().parent();
    if(sectionElement.hasClass('open')) {
      sectionElement.removeClass('open').addClass('close');
    } else {
      sectionElement.removeClass('close').addClass('open');
    }
  }

  collapseElement() {
    event.preventDefault();
    // var url = $(event.currentTarget).attr('href');
    // $('.element-items li').removeClass('active');
    // $('.element-container').remove();
    $('.pages-header .element-expand').show();
    $('.layer-container').removeClass('element-open');
    // $(document.getElementById('preview-iframe').contentWindow.document).find('.iframe-holder').removeClass('active');
    // history.pushState(null, '', url);
    $('.element-container').hide();
    this.sizeUpIframe();
  }

  expandElement() {
    event.preventDefault();
    $('.pages-header .element-expand').hide();
    $('.layer-container').addClass('element-open');
    $('.element-container').show();
    this.sizeUpIframe();
  }

  selectElement() {
    var _this = this;
    var currentTarget = event.currentTarget;
    var url = $(currentTarget).data('url');
    if(event.target.tagName == 'IMG' || event.target.tagName == 'A') { } else {
      var id = $(currentTarget).attr('id').replace('element-selector', 'iframe-holder')
      $('.element-items li').removeClass('active');
      $(currentTarget).addClass('active');
      $('.element-container').remove();
      $('.layer-container').addClass('element-open');
      $('.layer-container').removeClass (function (index, css) {
        return (css.match (/(^|\s)element-item-\S+/g) || []).join(' ');
      });
      $.each($(currentTarget).attr('class').split(/\s+/), function(index, item) {
        if(item.startsWith("element-item-")) {
          $('.layer-container').addClass(item);
        }
      });
      $('.layer-container').prepend('<div class="element-container"><div class="loading">Loading ...</div></div>');
      $(document.getElementById('preview-iframe').contentWindow.document).find('.iframe-holder').removeClass('active');
      $(document.getElementById('preview-iframe').contentWindow.document.getElementById(id)).addClass('active');
      history.pushState(null, '', url);
      _this.sizeUpIframe();
      $.ajax({
        url: url + '?partial'
      }).done(function(data){
        $('.element-container').remove();
        $('.layer-container').prepend(data);
        _this.sizeUpIframe();
      })
    }
  }

  bindColorPicker() {
    $.each($('.elements-container .color-picker'), function( key, value ) {
      var element = $(value)
      var pickr = Pickr.create({
        el: '#' + element.attr('id'),
        theme: 'nano',
        default: element.val(),
        comparison: true,
        useAsButton: true,
        components: {
          preview: true,
          opacity: true,
          hue: true,
          interaction: {
            hex: false,
            rgba: false,
            hsla: false,
            hsva: false,
            cmyk: false,
            input: true,
            clear: false,
            save: true
          }
        }
      });
      pickr.on('save', (color, instance) => {
        element = instance.getRoot().button
        $(element).val(color.toHEXA().toString());
        instance.hide();
      })
    });
  }

  sizeUpIframe() {
    var width = $('.layer-preview').width();
    var layerHeight = parseInt($('[data-controller=layer]').data('height'));
    var layerWidth = parseInt($('[data-controller=layer]').data('width'));
    var height = ((layerHeight/layerWidth)*width);
    var scale = Math.min(width/layerWidth,height/layerHeight).toPrecision(3);
    if(layerWidth < width) {
      $('.iframe-container').css({
        width: "fit-content",
        'margin-left': 'auto',
        'margin-right': 'auto',
        'max-height': height
      });
    } else {
      $('.iframe-container').css({
        width: "100%",
        'max-height': height
      });
    }
    if(scale < 1) {
      $('iframe.preview').css({
        transform: "scale("+ scale + ")"
      });
    }
    if($('.elements-section').length > 0 ) {
      var elementsSectionHeight = document.body.offsetHeight - $('.pages-section').height() - 62;
      if(elementsSectionHeight > 124) {
        $('.elements-section').height(document.body.offsetHeight - $('.pages-section').height() - 62);
      } else {
        $('.pages-section').height('50%');
        $('.elements-section').height('50%');
        $('.elements-section').css({ top: '50%'});
      }
      Cookies.set('sidebarSize', JSON.stringify({
        'pages-section': {
          height: $('.pages-section').height()
        },
        'elements-section': {
          height: $('.elements-section').height(),
          top: $('.elements-section').position().top
        }
      }));
    }
  }
}
