import { Controller } from '@hotwired/stimulus';
import Pusher from 'pusher-js'

export default class extends Controller {
  static targets = [ "expanded", "fontS", "fontM", "fontL", "fontXL" ]

  connect() {
    window.notifyVisibleChats = function(ids) {
      $('#stream_chat_list .chat-row').removeClass('active');
      $(ids).each(function(index){
        $('#stream_chat_list .chat-row[data-id='+this+']').addClass('active');
      })
    }
    this.loadChat();
    $('.stream-chat-container').on("scroll", this.checkScrollChatList);
    this.pusher = new Pusher(process.env.PUSHER_KEY, {
      cluster: process.env.PUSHER_CLUSTER
    });
    this.projectChannel = this.pusher.subscribe("chat_messages_" + this.data.get('projectId'));
    this.elementChannel = this.pusher.subscribe("element_" + this.data.get('elementId'));
    this.elementChildChannel = this.pusher.subscribe("element_" + this.data.get('childElementId'));
    this.projectChannel.bind('new', this.addNewChat);
    this.elementChildChannel.bind('update', function(data) {
      if(data.changes.includes('selection_type')) {
        Turbolinks.visit(window.location.href);
      }
    });
    $('#stream_chat_list').on("click", ".chat-row", function() {
      if($(event.target).hasClass('click-close') || $(event.target).hasClass('chat-action')) {
      } else {
        $('#stream_chat_list .chat-row').removeClass('active-click');
        $(this).addClass('active-click');  
      }
    });
  }

  disconnect() {
    this.pusher.disconnect();
  }

  loadChat() {
    var url = this.data.get('url')
    $.ajax({ url: url }).done(function(data){
      $('.stream-chat-container .loading').hide()
      if(data.length > 1) {
        $('.stream-chat-container #stream_chat_list').html(data)
      } else {
        $('.stream-chat-container .dummy').show()
      }
    });
  }

  addNewChat = (data) => {
    var template = document.querySelector('#chat_row');
    var parentContainer = $('.stream-chat-container')[0];
    var parent = document.querySelector('#stream_chat_list');
    var clone = template.content.cloneNode(true);
    clone.firstElementChild.dataset['id'] = data.id
    clone.querySelector('.chat-row--author-name').textContent = (data.author_display_name || data.author_name)
    clone.querySelector('.chat-row--source').classList.add(data.event_source.toLowerCase().split(' ')[0]);
    clone.querySelector('.chat-row--text').innerHTML = data.text
    if(clone.querySelector('a.click-hide')) {
      clone.querySelector('a.click-hide').dataset['id'] = data.id
    }
    if(clone.querySelector('a.click-show')) {
      clone.querySelector('a.click-show').dataset['id'] = data.id
    }
    // clone.querySelector('a.queue').dataset['id'] = data.id
    // clone.querySelector('a.fav').dataset['id'] = data.id
    // clone.querySelector('a.jump-queue').dataset['id'] = data.id
    $('.stream-chat-container .dummy').hide()
    if(parentContainer.scrollTop === 0){
      $(parent).prepend(clone);
      parentContainer.scrollTo(0,0);
    } else {
      $(parent).prepend(clone);
      $('.new-messages').show();
    }
  }

  scrollToNewMessage = () => {
    $('.stream-chat-container').animate({ scrollTop: 0}, 1)
    $('.new-messages').hide();
  }

  checkScrollChatList = () => {
    if((document.getElementById('stream_chat_list').offsetHeight - $('.stream-chat-container').scrollTop()) < 500) {
      $('.stream-chat-container').off("scroll");
      this.loadPreviousChatList();
    }
  }

  closeClickOverlay = () => {
    event.preventDefault();
    $(event.currentTarget).parent().parent().removeClass('active-click');
  }

  loadPreviousChatList = () => {
    var url = this.data.get('url');
    var lastId = $('.stream-chat-container #stream_chat_list .chat-row').last().data('id');
    url = url + '?last_id=' + lastId;
    var _this = this
    $.ajax({
      url: url
    }).done(function(data){
      $('.stream-chat-container #stream_chat_list').append(data)
      var newLastId = $('.stream-chat-container #stream_chat_list .chat-row').last().data('id');
      if(lastId !== newLastId) {
        $('.stream-chat-container').on("scroll", _this.checkScrollChatList);
      }
    });
  }

  addToQueue = () => {
    event.preventDefault()
    var url = this.data.get('addToQueueUrl').replace('--ID--', event.currentTarget.dataset.id)
    $.ajax({ type: "POST", url: url })
  }

  removeFromQueue = () => {
    event.preventDefault()
    var url = this.data.get('removeFromQueueUrl').replace('--ID--', event.currentTarget.dataset.id)
    $.ajax({ type: "POST", url: url })
  }
}
