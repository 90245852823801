import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "expanded", "checkbox" ]

  connect() {
    var _this = this
    var chatContainer = $('.stream-chat-container')
    $(_this.expandedTarget).on('change', function() {
      if(_this.expandedTarget.checked) {
        chatContainer.addClass('expanded')
      } else {
        chatContainer.removeClass('expanded')
      }
    });
  }

  toggleCheckbox(event) {
    var chatContainer = $('.stream-chat-container')
    this.checkboxTargets.forEach(target => target.checked = false)
    chatContainer.removeClass(this.checkboxClasses)
    let checkbox = event.target.closest("input")
    chatContainer.addClass(checkbox.getAttribute("data-checkbox-class"))
    checkbox.checked = true
  }

  toggleVisibility(event) {
    event.preventDefault();
    if($(event.currentTarget).parent().hasClass('closed')) {
      $('.layer-container').addClass('with-stream-chat');
      $(event.currentTarget).parent().removeClass('closed')
    } else {
      $('.layer-container').removeClass('with-stream-chat');
      $(event.currentTarget).parent().addClass('closed')
    }
    this.layerController().sizeUpIframe();
  }

  layerController() {
    return this.application.getControllerForElementAndIdentifier($('[data-controller=layer]')[0], "layer")
  }

  get checkboxClasses() {
    return this.checkboxTargets.map(target => target.getAttribute("data-checkbox-class"))
  }

}