import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js'

export default class extends Controller {
  connect() {
    const _this = this;
    if(!window.fabUiQ) {
      window.fabUiQ = []
    }
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var elementId = this.data.get('elementId')
      var elementChannel = window.pusher.subscribe("element_" + elementId);
      elementChannel.bind('update', function(data) {
        window.parent.resizeIframe && data.height && data.width && window.parent.resizeIframe(data.height, data.width, data.xPos, data.yPos, elementId);
        Turbolinks.visit(window.location.href);
      });
      elementChannel.bind('broadcast_animation', function(data) {
        window.fabUiQ.push({ data: data, type: 'broadcast_animation' })
        if($('.boosted_comment:visible, .broadcast_animation:visible').length == 0) {
          _this.displayItem()
        }
      });
      elementChannel.bind('boosted_comment', function(data) {
        window.fabUiQ.push({ data: data, type: 'boosted_comment' })
        if($('.boosted_comment:visible, .broadcast_animation:visible').length == 0) {
          _this.displayItem()
        }
      });
    }
  }

  displayItem() {
    const _this = this;
    const item = window.fabUiQ.shift();
    if(item) {
      const data = item.data;
      const type = item.type;
      switch(type) {
        case 'broadcast_animation':
          $('.boosted_comment').hide();
          $('.broadcast_animation').css({ 'background-image': 'url("' + data.animationUrl + '")' });
          $('.broadcast_animation .avatar').css({ 'background-image': 'url("' + data.author.photoThumbUrl + '")' });
          $('.broadcast_animation .username').html(data.author.displayName || data.author.name);
          $('.broadcast_animation .animation-name').html(data.animationName);
          $('.broadcast_animation').show();
          break;
        case 'boosted_comment':
          $('.broadcast_animation').hide();
          $('.boosted_comment .username').html((data.author.displayName || data.author.name) + ":")
          $('.boosted_comment .text').html(data.text)
          $('.boosted_comment').show();
          break;
      }
      window.setTimeout(function() {
        _this.displayItem();
      }, 5000);
    } else {
      $('.broadcast_animation').hide();
      $('.boosted_comment').hide();
    }
  }
}
