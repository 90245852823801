import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $('a.icon-expand').click(function(){
      if($('.new-layout').hasClass('layers-menu-open')) {
        $('.new-layout').removeClass('layers-menu-open');
      } else {
        $('.new-layout').addClass('layers-menu-open');
      }
      return false;
    });
  }

  restream() {
    if(event.currentTarget.checked) {
      var url = event.currentTarget.dataset.linkUrl;
      window.location.href = url;
    } else {
      var url = event.currentTarget.dataset.unlinkUrl;
      $.ajax({
        type: "DELETE",
        url: url
      })
    }
  }

  youtube() {
    if(event.currentTarget.checked) {
      $(event.currentTarget).parents('.connect-platform-box').find('a')[0].click();
    } else {
      var url = event.currentTarget.dataset.unlinkUrl;
      $.ajax({
        type: "DELETE",
        url: url
      })
    }
  }

  facebook() {
    if(event.currentTarget.checked) {
      $(event.currentTarget).parents('.connect-platform-box').find('a.auth-link')[0].click();
    } else {
      var url = event.currentTarget.dataset.unlinkUrl;
      $.ajax({
        type: "DELETE",
        url: url
      })
    }
  }

  linkedin() {
    if(event.currentTarget.checked) {
      $(event.currentTarget).parents('.connect-platform-box').find('a')[0].click();
    } else {
      var url = event.currentTarget.dataset.unlinkUrl;
      $.ajax({
        type: "DELETE",
        url: url
      })
    } 
  }

  twitch() {
    if(event.currentTarget.checked) {
      $(event.currentTarget).parents('.connect-platform-box').find('a')[0].click();
    } else {
      var url = event.currentTarget.dataset.unlinkUrl;
      $.ajax({
        type: "DELETE",
        url: url
      })
    } 
  }
}
