import { Controller } from "@hotwired/stimulus";
import Pusher from "pusher-js";
import moment from "moment";
import "moment-timezone";

export default class extends Controller {
  connect() {
    document.getElementById("timezone").value = moment().format("Z");
  }

  restoreFilteredWords() {
    event.preventDefault();
    $.ajax({ type: "POST", url: this.data.get("restoreFilteredWordsUrl") });
  }

  restoreFilteredCharacters() {
    event.preventDefault();
    $.ajax({ type: "POST", url: this.data.get("restoreFilteredCharactersUrl") });
  }
}
