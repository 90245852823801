import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js';

export default class extends Controller {
  connect() {
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var elementId = this.data.get('elementId')
      var elementChannel = window.pusher.subscribe("element_" + elementId);
      elementChannel.bind('update', function(data) {
        window.parent.resizeIframe && window.parent.resizeIframe(data.height, data.width, data.xPos, data.yPos, elementId);
        Turbolinks.visit(window.location.href);
      });
    }
  }
}
