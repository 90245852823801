import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js'
import WordCloud from 'wordcloud';
import html2canvas from 'html2canvas';
import canvas2image from "canvas2image-2";

export default class extends Controller {
  connect() {
    var _this = this
    window.isReloading = false
    var width = this.data.get('width');
    var height = this.data.get('height');
    var fontColor = this.data.get('fontColor');
    var styleType = this.data.get('styleType');
    if(styleType == 'Cloud') {
      var canvas = $('#canvas');
      var devicePixelRatio = parseFloat(1);
      var pixelWidth = width * devicePixelRatio;
      var pixelHeight = height * devicePixelRatio;
      var htmlCanvas = $('#html-canvas');
      canvas.css({'width': width + 'px', 'height': height + 'px'});
      canvas.attr('width', pixelWidth);
      canvas.attr('height', pixelHeight);
      htmlCanvas.css({'width': pixelWidth + 'px', 'height': pixelHeight + 'px'});
      var list = []
      $.each($('#list').val().split("\n"), function each(i, line) {
        if (!$.trim(line))
          return;
        var lineArr = line.split(' ');
        var count = parseFloat(lineArr.shift()) || 0;
        list.push([lineArr.join(' '), count]);
      });
      var options = {
        list: list,
        weightFactor: function (size) {
          if(size < 20) {
            return 20
          } else {
            return size
          }
        },
        fontFamily: this.data.get('font'),
        gridSize: 14,
        color: function (word, weight) {
          return fontColor;
        },
        rotateRatio: 0,
        // rotationSteps: 2,
        shrinkToFit: true,
        clearCanvas: false,
        drawMask: true,
        drawOutOfBound: false,
        abortThreshold: 1000
      }
      var maskUrl = this.data.get('mask');
      var img = new Image();
      img.src = maskUrl;
      img.onload = function readPixels() {
        var maskCanvas = document.createElement('canvas');
        var actualImgHeight
        var actualImgWidth
        actualImgWidth = width
        actualImgHeight = (img.height / img.width) * width;
        if(actualImgHeight > height) {
          actualImgHeight = height
          actualImgWidth = height / (img.height / img.width)
        }
        canvas.css({'width': actualImgWidth + 'px', 'height': actualImgHeight + 'px'});
        canvas.attr('width', actualImgWidth);
        canvas.attr('height', actualImgHeight);
        htmlCanvas.css({'width': actualImgWidth + 'px', 'height': actualImgHeight + 'px'});
        maskCanvas.width = img.width;
        maskCanvas.height = img.height;
        var ctx = maskCanvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var imageData = ctx.getImageData(
          0, 0, maskCanvas.width, maskCanvas.height);
        var newImageData = ctx.createImageData(imageData);
        for (var i = 0; i < imageData.data.length; i += 4) {
          var tone = imageData.data[i] +
            imageData.data[i + 1] +
            imageData.data[i + 2];
          var alpha = imageData.data[i + 3];

          if (alpha < 128 || tone > 128 * 3) {
            newImageData.data[i] =
              newImageData.data[i + 1] =
              newImageData.data[i + 2] = 255;
            newImageData.data[i + 3] = 0;
          } else {
            newImageData.data[i] =
              newImageData.data[i + 1] =
              newImageData.data[i + 2] = 0;
            newImageData.data[i + 3] = 255;
          }
        }
        ctx.putImageData(newImageData, 0, 0);
        var bctx = document.createElement('canvas').getContext('2d');
        bctx.fillStyle = options.backgroundColor || '#fff';
        bctx.fillRect(0, 0, 1, 1);
        var bgPixel = bctx.getImageData(0, 0, 1, 1).data;
        var maskCanvasScaled =
          document.createElement('canvas');
        maskCanvasScaled.width = canvas[0].width;
        maskCanvasScaled.height = canvas[0].height;
        ctx = maskCanvasScaled.getContext('2d');
        ctx.drawImage(maskCanvas,
          0, 0, maskCanvas.width, maskCanvas.height,
          0, 0, maskCanvasScaled.width, maskCanvasScaled.height);
        var imageData = ctx.getImageData(0, 0, canvas[0].width, canvas[0].height);
        var newImageData = ctx.createImageData(imageData);
        for (var i = 0; i < imageData.data.length; i += 4) {
          if (imageData.data[i + 3] > 128) {
            newImageData.data[i] = bgPixel[0];
            newImageData.data[i + 1] = bgPixel[1];
            newImageData.data[i + 2] = bgPixel[2];
            newImageData.data[i + 3] = bgPixel[3];
          } else {
            newImageData.data[i] = bgPixel[0];
            newImageData.data[i + 1] = bgPixel[1];
            newImageData.data[i + 2] = bgPixel[2];
            newImageData.data[i + 3] = bgPixel[3] ? (bgPixel[3] - 1) : 0;
          }
        }
        ctx.putImageData(newImageData, 0, 0);
        ctx = canvas[0].getContext('2d');
        ctx.drawImage(maskCanvasScaled, 0, 0);
        maskCanvasScaled = ctx = imageData = newImageData = bctx = bgPixel = undefined;
        WordCloud(
          [canvas[0], htmlCanvas[0]],
          options 
        );      
      };
    }
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var elementId = this.data.get('elementId')
      var elementChannel = window.pusher.subscribe("element_" + elementId);
      elementChannel.bind('update', function(data) {
        window.parent.resizeIframe && data.height && data.width && window.parent.resizeIframe(data.height, data.width, data.xPos, data.yPos, elementId);
        if(!window.isReloading) {
          window.isReloading = true
          Turbolinks.visit(window.location.href);
        }
      });  
    }
    if(_this.data.get('download') == 'true') {
      setTimeout(function(){
        html2canvas(document.getElementById('tracker-container')).then(canvas => {
          return canvas2image.saveAsPNG(canvas, document.getElementById('tracker-container').offsetWidth, document.getElementById('tracker-container').offsetHeight);
        })
      }, 1000)
      setTimeout(function(){
        window.close();
      }, 1500)
    }
  }
}
