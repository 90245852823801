import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context(".", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

document.addEventListener("turbolinks:before-visit", function(event) {
  Turbolinks.savedScrolls = {
    'layers-list': document.getElementById('layers-list') && document.getElementById('layers-list').scrollTop,
    'elements-list': document.getElementById('elements-list') && document.getElementById('elements-list').scrollTop
  }
});
