import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["infoModal"];

  connect() {
    $(".account-setting-left-na .setting-nav-link").removeClass(
      "setting-nav-link-active"
    );
    $("#" + $(".account-setting-left-nav").data("selected-tab")).addClass(
      "setting-nav-link-active"
    );
    $.each($("#flash div"), function (key, value) {
      var element = $(value);
      var style = element.attr("class");
      switch (style) {
        case "alert":
          style = "error";
          break;
      }
      $.growl({
        title: "",
        message: element.text(),
        style: style,
        duration: 10000,
      });
    });
  }

  showInfoPopup() {
    event.preventDefault();
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.infoModalTarget,
      "modal"
    );
    if (event.currentTarget.dataset) {
      $(this.infoModalTarget)
        .find("#modal_heading")
        .text(event.currentTarget.dataset.modalTitle);
      $(this.infoModalTarget)
        .find("#modal_desc")
        .text(event.currentTarget.dataset.modalDesc);
    }
    modalController.open();
  }
}
