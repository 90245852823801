import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js'
import FontPicker from "font-picker";
import html2canvas from 'html2canvas';
import canvas2image from "canvas2image-2";

export default class extends Controller {

  connect() {
    var interval = null;
    var lastResult;
    if(
        $('.poll_container').hasClass('poll-style-1') ||
        $('.poll_container').hasClass('poll-style-2') ||
        $('.poll_container').hasClass('poll-style-3') ||
        $('.poll_container').hasClass('poll-style-6')
      ) {
      const widths = [].slice.call(document.getElementsByClassName('hashtag'))
        .map(function(div){ return div.offsetWidth; });
      const maxWidth = (Math.max.apply(Math, widths) + 20)
      $('.hashtag').css('width', maxWidth);
      const optionContainer = document.getElementsByClassName('option_container')[0];
      if(optionContainer) {
        if($('.poll_container').hasClass('poll-style-6')) {
          $('.hashtag_label').css('width', optionContainer.offsetWidth - maxWidth - 57);
        } else {
          $('.hashtag_label').css('width', optionContainer.offsetWidth - maxWidth - 47);
        }
      }
      $('.poll-divider').css('height', (parseInt($('.options-container').css('height')) + 12));
    }
    new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.data.get('font-1'),
      {
        variants: ['regular', '700'],
        limit: 1,
        pickerId: '1'
      },
    );
    new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.data.get('font-2'),
      {
        variants: ['regular', '700'],
        limit: 1,
        pickerId: '2'
      },
    );
    new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.data.get('font-3'),
      {
        variants: ['regular', '700'],
        limit: 1,
        pickerId: '3'
      },
    );
    new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.data.get('font-4'),
      {
        variants: ['regular', '700'],
        limit: 1,
        pickerId: '4'
      },
    );
    new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.data.get('font-5'),
      {
        variants: ['regular', '700'],
        limit: 1,
        pickerId: '5'
      },
    );
    
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var elementId = this.data.get('elementId')
      var elementChannel = window.pusher.subscribe("element_" + elementId);
      elementChannel.bind('update', function(data) {
        console.log('data', data);
        window.parent.resizeIframe && window.parent.resizeIframe(data.height, data.width, data.xPos, data.yPos, elementId);
        Turbolinks.visit(window.location.href);
      });
      elementChannel.bind('update_results', function(data) {
        if($('.poll_container').hasClass('init_results') && !interval) {
          interval = setInterval(function(){
            $('.option_container').each(function(){
              var random = Math.floor(Math.random() * 80) + 10;
              $(this).find('.results_fill').css('width', random + '%');
            })
          }, 1000)
          setTimeout(function(){
            clearInterval(interval);
            $('.poll_container').removeClass('init_results');
            if(lastResult) {
              lastResult.forEach(function(answer){
                $('[data-answer-id='+answer.id+'] .results_fill').css('width', answer.percentage + '%');
                $('[data-answer-id='+answer.id+'] .results_percentage').html(answer.percentage + '%');
              })
            }
          }, 5000)
        }
        if(interval) {
          lastResult = data
        }
        if(!$('.poll_container').hasClass('init_results')) {
          data.forEach(function(answer){
            $('[data-answer-id='+answer.id+'] .results_fill').css('width', answer.percentage + '%');
            $('[data-answer-id='+answer.id+'] .results_percentage').html(answer.percentage + '%');
          })
        }
      });
    }
    if(this.data.get('download') == 'true') {
      setTimeout(function(){
        html2canvas(document.getElementById('capture')).then(canvas => {
          return canvas2image.saveAsPNG(canvas, document.getElementById('capture').offsetWidth, document.getElementById('capture').offsetHeight);
        })
      }, 1000)
      setTimeout(function(){
        window.close();
      }, 1500)
    }
  }

}
