import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["updateUserModal"];

  updateUser() {
    event.preventDefault();
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.updateUserModalTarget,
      "modal"
    );
    if (event.currentTarget.dataset) {
      let title = event.currentTarget.dataset.label;
      let value = document.getElementById(
        event.currentTarget.dataset.fieldId
      ).value;
      $(this.updateUserModalTarget).find(".modal-content-heading").text(title);
      this.updateUserModalTarget.querySelector("input[type='text']").value =
        value;
      this.updateUserModalTarget.querySelector(
        "input[type='text']"
      ).dataset.targetField = event.currentTarget.dataset.fieldId;
    }
    modalController.open();
  }

  submitUserForm() {
    if (event.currentTarget.classList.contains("setting-select")) {
      document.getElementById("user_profile_form").submit();
    } else {
      let inputField =
        this.updateUserModalTarget.querySelector("input[type='text']");
      let newVal = inputField.value;
      let targetFieldId =
        this.updateUserModalTarget.querySelector("input[type='text']").dataset
          .targetField;
      let validInput = false;

      //check if valid email formal
      if (targetFieldId.indexOf("email") > 0) {
        validInput = this.validateEmail(newVal);
      } else {
        validInput = newVal.length > 0;
      }
      if (validInput) {
        inputField.classList.remove("error");
        this.updateUserModalTarget.querySelector(".error-msg").style.display =
          "none";
        document.getElementById(targetFieldId).value = newVal;
        document.getElementById("user_profile_form").submit();
      } else {
        inputField.classList.add("error");
        this.updateUserModalTarget.querySelector(".error-msg").style.display =
          "block";
      }
    }
  }

  validateEmail(emailId) {
    let mailformat =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return emailId.match(mailformat);
  }
}
