import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['audio'];

    connect() {
        let elementId = this.data.get("element-id");

        localStorage.setItem(this.data.get("element-id"), this.audioTarget.currentTime);

        this.pusher = new Pusher(process.env.PUSHER_KEY, {
            cluster: process.env.PUSHER_CLUSTER
        });

        this.elementChannel = this.pusher.subscribe("element_" + elementId);

        this.isPlaying = false; // Maintain the play status

        this.elementChannel.bind("audio_status_change", (data) => {
            const audioData = data.data.attributes;
            const audioUrl = audioData.file.url;
            if (audioUrl) {
                if (!this.audioTarget.src) {
                    this.updateAudioSrc(audioUrl);
                }
                if (audioData.status === 'playing' && !this.isPlaying) {
                    this.playAudio();
                } else if (audioData.status === 'paused' && this.isPlaying) {
                    this.pauseAudio();
                } else if (audioData.status === 'stopped') {
                    this.stopAudio();
                }
            }
        });

        const storedTime = localStorage.getItem(elementId);
        if (storedTime) {
            this.data.set('currentTime', storedTime);
        }
    }

    updateAudioSrc(audioUrl) {
        this.audioTarget.src = audioUrl;
        this.audioTarget.load();
    }

    playAudio() {
        let currentTime = parseFloat(localStorage.getItem(this.data.get("element-id"))) || 0;
        this.audioTarget.currentTime = currentTime;

        if (this.audioTarget.currentTime >= this.audioTarget.duration) {
            this.audioTarget.currentTime = 0;
            currentTime = 0;
        }
        const onCanPlay = () => {
            this.audioTarget.load();
            console.debug(currentTime);
            this.audioTarget.currentTime = currentTime;
        
            this.audioTarget.play().then(() => {
                console.debug(this.audioTarget.currentTime);
                this.isPlaying = true;
            }).catch((error) => {
              console.error('Play error:', error);
            });
            this.audioTarget.removeEventListener('canplay', onCanPlay);
          };
          this.audioTarget.addEventListener('canplay', onCanPlay);
 
    }

    pauseAudio() {
        this.data.set('currentTime', this.audioTarget.currentTime);
        localStorage.setItem(this.data.get("element-id"), this.audioTarget.currentTime);
        this.audioTarget.pause();
        this.isPlaying = false;
    }

    stopAudio() {
        this.audioTarget.pause();
        this.audioTarget.currentTime = 0;
        localStorage.setItem(this.data.get("element-id"), 0);
        this.isPlaying = false;
    }
}