import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js';
import FontPicker from "font-picker";
import html2canvas from 'html2canvas';
import canvas2image from "canvas2image-2";

export default class extends Controller {
  static values = { studio: String }

  connect() {
    const studio = this.data.get('studio');
    const pro = this.data.get('pro') == 'true' ? true : false;
    var _this = this
    if(!window.timeoutChatInProgress) {
      window.timeoutChatInProgress = false
    }
    window.visibleChats = []
    _this.notifyVisibleChats(studio);
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var elementId = this.data.get('elementId')
      var elementChildId = this.data.get('elementChildId')
      var elementChannel = window.pusher.subscribe("element_" + elementId);
      var elementChildChannel = window.pusher.subscribe("element_" + elementChildId);
      elementChildChannel.bind('update', function(data) {
        window.parent.resizeIframe && window.parent.resizeIframe(data.height, data.width, data.xPos, data.yPos, elementChildId);
        Turbolinks.visit(window.location.href);
        if(window.chatTimer) {
          clearInterval(window.chatTimer);
          window.chatTimer = undefined;
        }
      });
      elementChannel.bind('remove_from_queue', function(data) {
        $('.chat-row-preview').remove();
        if(pro) {
          if(data.reset_timer) {
            if(window.chatTimer) {
              clearInterval(window.chatTimer);
              window.chatTimer = undefined;
            }
          }
          Turbolinks.visit(window.location.href);
        } else {
          $('#chat-container .chat-row[data-chat-id='+data.chat_id+']').remove();
          _this.notifyVisibleChats(studio);
        }
      });
      elementChannel.bind('add_to_queue', function(data) {
        $('.chat-row-preview').remove();
        if(pro) {
          var chatId = document.getElementById('chat-container').dataset.displayElementChatChatId
          if(!chatId) {
            Turbolinks.visit(window.location.href);
          }
        } else {
          if(_this.data.get('display-order') == '1') {
            $('#chat-container').append(data.html);
          } else {
            $('#chat-container').prepend(data.html);
          }
          _this.notifyVisibleChats(studio);
        }
      });
    }
    if(!window.chatTimer || window.chatDelay != this.data.get('delay')) {
      window.chatDelay = this.data.get('delay')
      if(window.chatTimer) {
        clearInterval(window.chatTimer);
      }
      window.chatTimer = setInterval(this.timeoutChat, parseInt(window.chatDelay) * 1000);
    }
    if(this.data.get('download') == 'true') {
      setTimeout(function(){
        html2canvas(document.getElementById('chat-container')).then(canvas => {
          return canvas2image.saveAsPNG(canvas, document.getElementById('chat-container').offsetWidth, document.getElementById('chat-container').offsetHeight);
        })
      }, 1000)
      setTimeout(function(){
        window.close();
      }, 1500)
    }
  }

  timeoutChat = () => {
    if(!window.timeoutChatInProgress) {
      window.timeoutChatInProgress = true
      var chatId = undefined;
      if(this.data.get('display-order') == '1') {
        chatId = $('#chat-container .chat-row').first().data('chat-id');
      } else {
        chatId = $('#chat-container .chat-row').last().data('chat-id');
      }
      if(chatId) {
        var url = this.data.get('timeoutChatUrl').replace('--ID--', chatId)  
        $.ajax({
          type: "DELETE", 
          url: url
        }).then(function(){
          window.timeoutChatInProgress = false
        })
      } else {
        window.timeoutChatInProgress = false
      }
    }
  }

  notifyVisibleChats = (studio) => {
    var _this = this
    window.visibleChats = []
    $('#chat-container .chat-row').each(function(index) {
      if(_this.isInViewport(this)) {
        window.visibleChats.push($(this).data('id'))  
      } else {
        return false;
      }
    });
    if(studio == "true") {
      // TODO: ???
    } else {
      window.parent.parent.notifyVisibleChats && window.parent.parent.notifyVisibleChats(window.visibleChats);  
    }
  }

  isInViewport = (row) => {
    return ($(row).is(':visible') && $(row).offset().left < 20)
  }

}
