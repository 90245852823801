import { Controller } from '@hotwired/stimulus';
import Rails from "@rails/ujs";
import '@simonwep/pickr/dist/themes/nano.min.css';
import Pickr from '@simonwep/pickr';
import moment from 'moment';
import 'moment-timezone';

export default class extends Controller {
  static targets = ['newPollModal', 'newChatModal', 'newMeterModal', 'newCloudModal', 'newSparkModal', 'newTriviaModal', 'form', 'alertModel', 'changeWarningPopupModal'];

  connect() {
    var _this= this;
    $('#download_element_image').click(function(){
      window.open($(this).data('url') + '?download');
      return false;
    })
    if(!Turbolinks.scroll) {
      Turbolinks.scroll = {}
    }
    $('.element-container .header .title').hover(function(){
      $(this).hide();
      $('.element-container .header .title-edit').show();
      $('.element-container .header .title-edit input')[0].scrollLeft = 0;
      $('.element-container .header .title-edit input').off('mouseout');
      $('.element-container .header .title-edit input').mouseout(function(){
        $('.element-container .header .title-edit').hide();
        $('.element-container .header .title').show();
      });
    });
    $('.element-container .header .title-edit input').focus(function(){
      $('.element-container .header .title-edit input').off('mouseout');
    })
    $('.element-container .header .title-edit input').focusout(function(){
      var elementSelectorId = $(this).data('element-selector');
      $('#' + elementSelectorId + ' span.element-name').html($(this).val());
      $('#element_name').val($(this).val());
      $('.element-container .header .title').html($(this).val());
      Rails.fire(document.getElementById('element_name').form, 'submit');
      $('.element-container .header .title-edit').hide();
      $('.element-container .header .title').show();
    });
    $('.element-form form').on('submit', function(){
      if($('#reload').val() == 'yes') {
        Turbolinks.scroll['top'] = document.getElementsByClassName('container')[0].scrollTop;  
      }
    })
    if (Turbolinks.scroll['top']) {
      document.getElementsByClassName('container')[0].scrollTo(0, Turbolinks.scroll['top']);
      Turbolinks.scroll = {};
    }
    window.resizeIframe = function(height, width) {
      var element = document.getElementById('previewframe');
      if(element) {
        element.style.height = height + "px";
        element.style.width = width + "px";
      }
    }
    $('.poll-option').keyup(function(){
      if($(this).val().charAt(0) == '#') {
        $(this).val($(this).val().slice(1))
      }
    })
    $.each($('.element-container .color-picker'), function( key, value ) {
      var element = $(value)
      var pickr = Pickr.create({
        el: '#' + element.attr('id'),
        theme: 'nano',
        default: element.val(),
        comparison: true,
        useAsButton: true,
        components: {
          preview: true,
          opacity: true,
          hue: true,
          interaction: {
            hex: false,
            rgba: false,
            hsla: false,
            hsva: false,
            cmyk: false,
            input: true,
            clear: false,
            save: true
            // cancel: true
          }
        }
      });
      pickr.on('show', (color, instance) => {
        element = instance.getRoot().button
        window.pickrColor = $(element).val()
        window.pickrColorIgnore = true
        pickr.setColor(window.pickrColor, true)
      })
      pickr.on('save', (color, instance) => {
        element = instance.getRoot().button
        $(element).val(color.toHEXA().toString());
        instance.hide();
      })
      pickr.on('change', (color, source, instance) => {
        if(!window.pickrColorIgnore) {
          element = instance.getRoot().button
          $(element).val(color.toHEXA().toString());
          Rails.fire(element.form, 'submit');
          $(element).val(window.pickrColor);
          window.pickrColorIgnore = false
        }
      })
      pickr.on('hide', (instance) => {
        element = instance.getRoot().button
        Rails.fire(element.form, 'submit');
      })
    });
    $('.spark_option_file').on('direct-upload:start', (event) => {
      const { id, file } = event.detail
      const meter = $(event.currentTarget).parent().parent().find('.file-attached .meter')
      meter.css('display', 'block')
    });
    $('.spark_option_file').on('direct-upload:progress', (event) => {
      const { id, progress, file } = event.detail
      const meter = $(event.currentTarget).parent().parent().find('.file-attached .meter')
      meter.find('.meter-width').css('width', progress + '%')
    });
    $('.spark_option_file').on('direct-upload:end', (event) => {
      const { id, file } = event.detail
      const meter = $(event.currentTarget).parent().parent().find('.file-attached .meter')
      $('#reload').val('yes');
    });
    $('#element_image').on('direct-upload:start', (event) => {
      const { id, file } = event.detail
      const meter = $(event.currentTarget).parent().parent().find('.file-attached .meter')
      meter.css('display', 'block')
    });
    $('#element_image').on('direct-upload:progress', (event) => {
      const { id, progress, file } = event.detail
      const meter = $(event.currentTarget).parent().parent().find('.file-attached .meter')
      meter.find('.meter-width').css('width', progress + '%')
    });
    $('#element_image').on('direct-upload:end', (event) => {
      const { id, file } = event.detail
      const meter = $(event.currentTarget).parent().parent().find('.file-attached .meter')
      $('#reload').val('yes');
    });
    $('#element_poll_attributes_trivia').on('change', function(){
      $('#reload').val('yes');
    })
    $('#element_poll_attributes_hide').on('change', function(){
      if(!$('#element_poll_attributes_hide').is(":checked")) {
        $('#element_poll_attributes_hide_seconds').val('');
      }
      $('#reload').val('yes');
    })
    $('#element_poll_attributes_duration').on('change', function(){
      if(!$('#element_poll_attributes_duration').is(":checked")) {
        $('#element_poll_attributes_duration_seconds').val('');
      }
      $('#reload').val('yes');
    })
    $('#element_poll_attributes_scheduled').on('change', function(){
      if(!$('#element_poll_attributes_scheduled').is(":checked")) {
        $('#element_poll_attributes_scheduled_at').val('');
      } else {
        document.getElementById('element_poll_attributes_duration').checked = true
        document.getElementById('element_poll_attributes_hide').checked = true
        // $('#element_poll_attributes_duration_seconds').val(60);
      }
      $('#reload').val('yes');
    })
    $('#element_poll_attributes_style_type').on('click', function(){
      $(this).data('current-val', $(this).val());
    })
    $('#element_poll_attributes_style_type').on('change', function(){
      var currentVal = $(this).data('current-val');
      let modalController = _this.application.getControllerForElementAndIdentifier(
        _this.changeWarningPopupModalTarget,
        'modal'
      );
      modalController.open();
      $('.modal-change-warning-popup .cancel').unbind().click(function(){
        $('#element_poll_attributes_style_type').val(currentVal)
      })
      $('.modal-change-warning-popup .save').unbind().click(function(){
        modalController.close();
        $('#reload').val('yes');
        Rails.fire(document.getElementById('poll_element_form'), 'submit');
      })
      return false;
    })
    $('#element_meter_attributes_hide').on('change', function(){
      if(!$('#element_meter_attributes_hide').is(":checked")) {
        $('#element_meter_attributes_hide_seconds').val('');
      }
      $('#reload').val('yes');
    })
    $('#element_meter_attributes_duration').on('change', function(){
      if(!$('#element_meter_attributes_duration').is(":checked")) {
        $('#element_meter_attributes_duration_seconds').val('');
      }
      $('#reload').val('yes');
    })
    $('#element_meter_attributes_scheduled').on('change', function(){
      if(!$('#element_meter_attributes_scheduled').is(":checked")) {
        $('#element_meter_attributes_scheduled_at').val('');
      } else {
        document.getElementById('element_meter_attributes_duration').checked = true
        document.getElementById('element_meter_attributes_hide').checked = true
        // $('#element_poll_attributes_duration_seconds').val(60);
      }
      $('#reload').val('yes');
    })
    $('#element_meter_attributes_style_type').on('click', function(){
      $(this).data('current-val', $(this).val());
    })
    $('#element_meter_attributes_style_type').on('change', function(){
      var currentVal = $(this).data('current-val');
      let modalController = _this.application.getControllerForElementAndIdentifier(
        _this.changeWarningPopupModalTarget,
        'modal'
      );
      modalController.open();
      $('.modal-change-warning-popup .cancel').unbind().click(function(){
        $('#element_meter_attributes_style_type').val(currentVal)
      })
      $('.modal-change-warning-popup .save').unbind().click(function(){
        modalController.close();
        $('#reload').val('yes');
        Rails.fire(document.getElementById('meter_element_form'), 'submit');
      })
      return false;
    })
    $('#timezone').val(moment().format('Z'));

    $('.gating-methods').select2({
      templateResult: formatDropdownElement,
      templateSelection: formatDropdownElement,
      minimumResultsForSearch: -1,
      placeholder: "Select gating method",
      allowClear: true
    });

    $('.gating-methods').on('select2:selecting', function (e) {
      let newVal = e.params.args.data.id;
      let newText = e.params.args.data.text.toLocaleLowerCase();
      let showAlert = false;
      if(newVal < 0){
        showAlert = true;
        $(_this.alertModelTarget).find('p').text("New gating methods need to be added in Account Settings by an account admin.");
      }else{
        let newToken = true;
        $(".gating-box table tr").find("td:first").each((i, el)=>{
          if($(el).text().toLocaleLowerCase()==newText){
            showAlert = true;
            $(_this.alertModelTarget).find('p').text("Gating method has already been added!");
          }
        });
      }
      if(showAlert){
        let modalController = _this.application.getControllerForElementAndIdentifier(
          _this.alertModelTarget,
          'modal'
        );
        modalController.open();
        return false;
      }
    });

    function formatDropdownElement(state){
      if (!state.id) { return state.text; }
      let subText = '';
      if(state.element.dataset.helpText){
        subText = `<small>${state.element.dataset.helpText}</small>`
      }
      var $state = $(
        `
        <div class='custom-dropdown'>
          <div style="margin-bottom: 5px; color: #E0CCC1">
              ${state.text}
          </div>
           ${subText}
        </div>
        `
      );
      return $state;
    }
  }
  changeWordCount() {
    $('#reload').val('yes');
  }

  changePosition() {
    var val = $(event.currentTarget).val();
    if(val) {
      $.ajax({
        url: event.currentTarget.dataset.url,
        type: 'POST',
        data: {
          position: val
        }
      })
      $(event.currentTarget).val('');
    }
  }

  resetElement() {
    event.preventDefault()
    var url = this.data.get('resetUrl');
    if(confirm("Are you sure? This will erase all data for this element?")) {
      $.ajax({ type: "POST", url: url })  
    }
  }

  updateElement() {
    event.preventDefault()
    var url = this.data.get('updateUrl');
    $.ajax({ type: "POST", url: url })
  }

  sectionToggle() {
    event.preventDefault()
    var sectionElement = $(event.currentTarget).parent().parent();
    if(sectionElement.hasClass('open')) {
      sectionElement.removeClass('open').addClass('close');
    } else {
      sectionElement.removeClass('close').addClass('open');
    }
  }

  newSpark() {
    event.preventDefault()
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.newSparkModalTarget,
        'modal'
    );
    modalController.open();
  }

  newTrivia() {
    event.preventDefault()
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.newTriviaModalTarget,
        'modal'
    );
    modalController.open();
  }

  newCloud() {
    event.preventDefault()
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.newCloudModalTarget,
        'modal'
    );
    modalController.open();
  }

  newPoll() {
    event.preventDefault()
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.newPollModalTarget,
        'modal'
    );
    modalController.open();
  }

  newChat() {
    event.preventDefault()
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.newChatModalTarget,
        'modal'
    );
    modalController.open();
  }

  newMeter() {
    event.preventDefault();
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.newMeterModalTarget,
        'modal'
    );
    modalController.open();
  }

  publish(event) {
    event.preventDefault();
    var url = this.data.get('url');
    $.ajax({
      type: "PATCH",
      url: url,
      data: {
        element: {
          active: 1
        }
      }
    }).done(function(data) {
      Turbolinks.visit(window.location.href);
    })
  }

  unpublish(event) {
    event.preventDefault();
    var url = this.data.get('url');
    $.ajax({
      type: "PATCH",
      url: url,
      data: {
        element: {
          active: 0
        }
      }
    }).done(function(data) {
      Turbolinks.visit(window.location.href);
    })
  }

  toggleSparkOn(event) {
    var url = this.data.get('url');
    $.ajax({
      type: "PATCH",
      url: url,
      data: {
        element: {
          spark_attributes: {
            id: event.currentTarget.dataset.sparkId,
            on: event.currentTarget.checked ? 1 : 0
          }
        }
      }
    }).done(function(data) {
      Turbolinks.visit(window.location.href);
    })
  }

  toggleMeterOn(event) {
    var url = this.data.get('url');
    $.ajax({
      type: "PATCH",
      url: url,
      data: {
        element: {
          meter_attributes: {
            id: event.currentTarget.dataset.meterId,
            on: event.currentTarget.checked ? 1 : 0
          }
        }
      }
    }).done(function(data) {
      Turbolinks.visit(window.location.href);
    })
  }

  toggleCloudOn(event) {
    var url = this.data.get('url');
    $.ajax({
      type: "PATCH",
      url: url,
      data: {
        element: {
          cloud_attributes: {
            id: event.currentTarget.dataset.cloudId,
            on: event.currentTarget.checked ? 1 : 0
          }
        }
      }
    }).done(function(data) {
      Turbolinks.visit(window.location.href);
    })
  }

  toggleTrackerOn(event) {
    var url = this.data.get('url');
    $.ajax({
      type: "PATCH",
      url: url,
      data: {
        element: {
          tracker_attributes: {
            id: event.currentTarget.dataset.trackerId,
            on: event.currentTarget.checked ? 1 : 0
          }
        }
      }
    }).done(function(data) {
      Turbolinks.visit(window.location.href);
    })
  }

  save(event) {
    if(!$(event.target).hasClass('position-control')) {
      Rails.fire(event.currentTarget, 'submit');  
    }
  }

  toggleGated(){
    $("#gating_method_box").toggle();
    Rails.fire(event.currentTarget.form, 'submit');  
  }

  removeImage() {
    let confirmed = confirm('Are you sure?')
    
    if(confirmed){
      let params = event.currentTarget.dataset

      Rails.ajax({
        context: this,
        type: 'delete',
        dataType: 'json',
        url: `/projects/${params.project}/layers/${params.layer}/elements/${params.element}/remove_image`,
        success: function () {},
        error: function () {}
      });
    }
  }
}
