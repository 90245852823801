import { Controller } from '@hotwired/stimulus';
require('jquery.growl');
import 'jquery.growl/stylesheets/jquery.growl.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
require("jquery-ui/ui/widgets/selectmenu");
import 'jquery-ui/themes/base/selectmenu.css';
import Rails from "@rails/ujs";
import moment from 'moment';
import 'moment-timezone';
import 'select2';
import 'select2/dist/css/select2.min.css';
import Pickr from '@simonwep/pickr';

export default class extends Controller {
  static targets = ['renameEventModal', 'alertModel', 'publishEventModel'];
  static values = {
    uuid: String
  }
  connect() {
    let _this = this;

    $('.event-setting-form-field').change(()=>{
      Rails.fire(event.currentTarget.form, 'submit');
      if($(event.currentTarget).hasClass('countdown-timer-switch')){
        $('#countdown-timer-box').toggle();
      }
    });

    $('.event-publish-field').click((e)=>{
      if(e.currentTarget.checked){
        e.preventDefault();
        _this.openModel(_this.publishEventModelTarget);
      }else{
        e.currentTarget.checked = false;
        Rails.fire(event.currentTarget.form, 'submit');
      }
    });

    $('#timezone').val(moment().format('Z'));

    $('input[data-save-on-blur="true"], textarea[data-save-on-blur="true"]').blur(()=>{
      // revert to default text if any available for textarea/input
      if($(event.currentTarget).val().length == 0 && event.currentTarget.dataset.defaultText && event.currentTarget.dataset.defaultText.length > 0 ){
        $(event.currentTarget).val(event.currentTarget.dataset.defaultText);
        $(event.currentTarget).trigger("keyup");
      }
      Rails.fire(event.currentTarget.form, 'submit');
    });

    $('input[data-save-on-blur="true"], textarea[data-save-on-blur="true"]').keyup(()=>{
      $('.save-status').text('DRAFT');
      $('.save-status').removeClass('green');
    });

    $('input[data-preview="true"]').keyup(()=>{
      let element = event.currentTarget;
     $(`#${$(element).data('target-button-id')}`).html($(element).val());
    });
    
    $('.logo-image-setting').select2({
      templateResult: formatLogoElement,
      templateSelection: formatLogoElement,
      minimumResultsForSearch: -1,
      placeholder: "Select a logo from the list",
      allowClear: true
    });

    $('.social-channel-selector').select2({
      templateResult: formatImageDropdownElement,
      templateSelection: formatImageDropdownElement,
      minimumResultsForSearch: -1,
      placeholder: "Select social channel",
      allowClear: true
    });
    
    $('.gating-options').select2({
      templateResult: formatDropdownElement,
      templateSelection: formatDropdownElement,
      minimumResultsForSearch: -1,
      placeholder: "Select gating option"
    });

    $('.custom-select-control .placeholder').click((e)=>{
      $(e.currentTarget).parent().toggleClass('open');
      e.stopPropagation();
    });

    $('body').click(()=>{
      $('.custom-select-control').removeClass('open');
    })

    $('.logo-image-setting').on('select2:selecting', function (e) {
      let newVal = e.params.args.data.id
      if(newVal < 0){
        // $(_this.alertModelTarget).find("p").text('New logo need to be updated in Account Settings by an account admin.')
        // _this.openModel(_this.alertModelTarget)
        e.currentTarget.parentElement.querySelector('.file-upper').click();
        return false
      }
    });

    $('.social-channel-selector').on('select2:selecting', function (e) {
      let newVal = e.params.args.data.id
      if(newVal < 0){
        $(_this.alertModelTarget).find("p").text('New Platforms need to be added in Account Settings by an account admin.')
        _this.openModel(_this.alertModelTarget)
        return false
      }

      let existingVal = false
      $('.social-channel-selector').each((e, obj)=>{
        if(obj.value && obj.value == newVal){
          existingVal = true;
        }
      });

      if(existingVal){
        $(_this.alertModelTarget).find("p").text('Channel is already selected, Please try another!')
        _this.openModel(_this.alertModelTarget)
        return false
      }
    });

    $('.social-channel-selector, .logo-image-setting, .gating-options').on('select2:select', function (e) {
      Rails.fire(e.currentTarget.form, 'submit');
    });

    $('.social-channel-selector').on('select2:clear', function (e) {
      let channel_id = e.currentTarget.dataset.eid;
      let uuid = _this.data.get('uuid');
      $.ajax({
        url: `/projects/${uuid}/event_social_channels/${channel_id}`,
        type: 'DELETE',
        success: function(result) {
        }
      });
    });

    function getImageDropdownTemplate(image, text, height){
      let imgTag = '';
      if(image){
        imgTag = `<div style='display: flex;justify-content: center;'><img style='height:${height}; width:auto' src="${image}" /></div>`
      }

      var $state = $(
        `
        <div style='display: flex; align-items: center;'>
          ${imgTag}
          <div style="margin-left: 5px; color: #E0CCC1">
              ${text}
          </div>
        </div>
        `
      );
      return $state;
    }

    function formatLogoElement(state){
      if(state.element){
        if($(state.element.parentElement).hasClass('display-none')){
          $(state.element.form).find(".select2-container").addClass('display-none');
        }else{
          $(state.element.form).find(".select2-container").removeClass('display-none');
        }
      }

      if (!state.id) { return state.text; }
      return getImageDropdownTemplate(state.element.dataset.image, state.text, '62px');
    }

    function formatImageDropdownElement(state){
      if (!state.id) { return state.text; }
      return getImageDropdownTemplate(state.element.dataset.image, state.text, '30px');
    }

    function formatDropdownElement(state){
      if (!state.id) { return state.text; }
      let subText = '';
      if(state.element.dataset.helpText){
        subText = `<small>${state.element.dataset.helpText}</small>`
      }
      var $state = $(
        `
        <div class='custom-dropdown'>
          <div style="margin-bottom: 5px; color: #E0CCC1">
              ${state.text}
          </div>
           ${subText}
        </div>
        `
      );
      return $state;
    }

    this.initInputCharCounts();
    this.initColorPicker();
  }
  
  initColorPicker(){
    $.each($('.settings-container .color-picker'), function( key, value ) {
      var element = $(value)
      var pickr = Pickr.create({
        el: '#' + element.attr('id'),
        theme: 'nano',
        default: element.val(),
        comparison: true,
        useAsButton: true,
        components: {
          preview: true,
          opacity: true,
          hue: true,
          interaction: {
            hex: false,
            rgba: false,
            hsla: false,
            hsva: false,
            cmyk: false,
            input: true,
            clear: false,
            save: true
          }
        }
      });
      pickr.on('save', (color, instance) => {
        element = instance.getRoot().button
        $(element).val(color.toHEXA().toString());
        instance.hide();
        Rails.fire(element.form, 'submit');
      })
    });
  }

  initInputCharCounts(){
    $('.char-limit').each((i, obj)=>{
      let maxLen = $(obj).attr("maxlength");
      let charLength = $(obj).val().length;
      $(obj).siblings('.landing-social-bar-msg, .field-sub-msg').text(`${charLength}/${maxLen} characters`);
    });

    $('.char-limit').keyup(()=>{
      let charLength = $(event.currentTarget).val().length
      let maxLength = $(event.currentTarget).attr("maxlength");
      $(event.currentTarget).siblings('.landing-social-bar-msg, .field-sub-msg').text(`${charLength}/${maxLength} characters`)
    });
  }

  openModel(targetModel){
    event.preventDefault()
    let modalController = this.application.getControllerForElementAndIdentifier(
        targetModel,
        'modal'
    );
    modalController.open();
  }

  closeModel(targetModel){
    event.preventDefault()
    let modalController = this.application.getControllerForElementAndIdentifier(
        targetModel,
        'modal'
    );
    modalController.close();
  }

  renameEvent() {
    openModel(this.renameEventModalTarget);
  }

  toggleClass(){
    let targetBox = $(event.currentTarget).data('collapse-panel');
    let className = $(event.currentTarget).data('toggle-class');
    $(event.currentTarget).parents(`.${targetBox}`).toggleClass(className);
  }

  updateEventChatAnimation(){
    let element = event.currentTarget
    let chatAnimationData = element.dataset
    let url = `/projects/${this.data.get('uuid')}/event_chat_animations`
    //delete it and remove selection
    if(chatAnimationData.eventChatAnimationId.length == 0){
      let reqData = {'event_chat_animation': { 'account_chat_animation_id':  chatAnimationData.accountChatAnimationId } }
      $.ajax({
        url: url,
        type: 'POST',
        data: reqData
      }).then(function(data){
        chatAnimationData.eventChatAnimationId = data.id
        $(element).addClass('sticker-selected')
      })
    }else{ //add new chat animation to account
      $.ajax({
        type: "DELETE", 
        url: `${url}/${chatAnimationData.eventChatAnimationId}`
      }).then(function(data){
        chatAnimationData.eventChatAnimationId = ''
        $(element).removeClass('sticker-selected')
      })
    }
  }

  publishEvent(){
    $('.event-publish-field').prop( "checked", true );
    this.closeModel(this.publishEventModelTarget);
    Rails.fire($('.event-publish-field').parents("form")[0], 'submit');
  }

  landingPageInfo(){
    $(this.alertModelTarget).find("h3").text('What is a Landing Page for?')
    $(this.alertModelTarget).find("p").text('Control the look and feel of your event by hosting it on a custom, branded page. These are great when it’s important to have control over the branding on the website or if you want to gate your event with tokens, NFTs, POAPs  vouchers or tickets.')
    this.openModel(this.alertModelTarget)
  }

  restoreChatGuideline(){
    $('.chat-rules').val(event.currentTarget.dataset.defaultText);
    Rails.fire($(event.currentTarget).parents('form')[0], 'submit');
  }

  restoreCountdownSettings(){
    $(event.currentTarget).parents('.landing-social-small').find('.landing-social-inner').each((_index, ele)=>{
      let defaultVal = $(ele).data('default-value');
      $(ele).find('input, select').val(defaultVal);
      if($(ele).data('controller') == 'google-font'){
        $(ele).find('.dropdown-font-family').text(defaultVal);
        $(ele).find('.font-list .active-font').removeClass('active-font');
        $(ele).find('.font-list button').each((_i, obj)=>{
          if($(obj).text() == defaultVal){
            $(obj).addClass('active-font');
          }
        });
      }
    });
    Rails.fire($(event.currentTarget).parents('form')[0], 'submit');
  }

  selectGatingMethod(){
    let newVal = event.currentTarget.dataset.id;
    let newText = event.currentTarget.dataset.text;
    if(parseInt(newVal) < 0){
      $(this.alertModelTarget).find("p").text('New gating methods need to be added in Account Settings by an account admin.')
      this.openModel(this.alertModelTarget)
      return false
    }else{
      let newToken = true;
      $("#project_gating_mehods tr").find("td:first").each((i, el)=>{
        if($(el).text().toLocaleLowerCase()==newText.toLocaleLowerCase()){
          newToken = false;
          $(this.alertModelTarget).find("p").text('Gating method has already been added!')
          this.openModel(this.alertModelTarget)
        }
      });
      if(newToken){
        $('.custom-select-control .placeholder').text(newText);
        $("#project_event_tokens_attributes_0_gate_token_id").val(newVal);
      }
    }
  }
}
