import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js'
import FontPicker from "font-picker";
import html2canvas from 'html2canvas';
import canvas2image from "canvas2image-2";

export default class extends Controller {
  connect() {
    const meter_type = this.data.get('type');
    if(meter_type == "3" || meter_type == "4" || meter_type == "12") {
      $('.meter_chart_container').css({
        height: $('.option').height() + 10
      })
    } else {
    // else if (meter_type == "1" || meter_type == "2" || meter_type == "11") {
      var new_height = this.data.get('height') - $('.question').outerHeight(true) - 50
      if(meter_type == "9" || meter_type == "10") {
        new_height = new_height - 60
      }
      var new_width = new_height / ( 450.0 / 900.0 )
      if(new_width > parseInt(this.data.get('width'))) {
        new_width = parseInt(this.data.get('width'))
        new_height = ( 450.0 / 900.0 ) * new_width
      }
      $('.meter_chart_container').css({
        height: new_height,
        width: new_width
      })
      $('#meter').css({
        height: new_height,
        width: new_width
      })
    }
    var _this = this;
    new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.data.get('font-1'),
      {
        variants: ['regular', '700'],
        limit: 1,
        pickerId: '1'
      },
    );
    new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.data.get('font-2'),
      {
        variants: ['regular', '700'],
        limit: 1,
        pickerId: '2'
      },
    );
    new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.data.get('font-3'),
      {
        variants: ['regular', '700'],
        limit: 1,
        pickerId: '3'
      },
    );
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var elementId = this.data.get('elementId')
      var elementChannel = window.pusher.subscribe("element_" + elementId);
      elementChannel.bind('update', function(data) {
        window.parent.resizeIframe && window.parent.resizeIframe(data.height, data.width, data.xPos, data.yPos, elementId);
        if(data.changes.length == 0) {
          if(meter_type == "3" || meter_type == "4" || meter_type == "12") {
            var per = data.percent
            var per1 = Math.max((data.percent - (Math.floor(Math.random() * 30))), 0)
            var per2 = Math.min((data.percent + (Math.floor(Math.random() * 30))), 100)
            var max_width = document.getElementsByClassName('meter_chart_container')[0].offsetWidth;
            var bar_left = (((max_width * per1) / 100.0) - 8.5)
            bar_left = bar_left < 0 ? 0 : (bar_left > max_width) ? max_width : bar_left
            $('#bar').css("right", bar_left)
            setTimeout(function(){
              bar_left = (((max_width * per2) / 100.0) - 8.5)
              bar_left = bar_left < 0 ? 0 : (bar_left > max_width) ? max_width : bar_left
              $('#bar').css("right", bar_left)
            }, 1000)
            setTimeout(function(){
              bar_left = (((max_width * per) / 100.0) - 8.5)
              bar_left = bar_left < 0 ? 0 : (bar_left > max_width) ? max_width : bar_left
              $('#bar').css("right", bar_left)
            }, 2000)
          } else {
            var deg = data.degree;
            var deg1 = Math.max((data.degree - (Math.floor(Math.random() * 30))), -90)
            var deg2 = Math.min((data.degree + (Math.floor(Math.random() * 30))), 90)
            $('#pointer').css("transform", "rotate(" + deg1 + "deg)")
            _this.applyPointerColor(deg1)
            setTimeout(function(){
              $('#pointer').css("transform", "rotate(" + deg2 + "deg)")
              _this.applyPointerColor(deg2)
            }, 1000)
            setTimeout(function(){
              $('#pointer').css("transform", "rotate(" + deg + "deg)")
              _this.applyPointerColor(deg)
            }, 2000)
          }
        } else {
          Turbolinks.visit(window.location.href);
        }
        Turbolinks.visit(window.location.href);
      });  
    }
    $('.meter_chart_container').css({
      visibility: 'visible'
    })
    if(this.data.get('download') == 'true') {
      setTimeout(function(){
        html2canvas(document.getElementById('capture')).then(canvas => {
          return canvas2image.saveAsPNG(canvas, document.getElementById('capture').offsetWidth, document.getElementById('capture').offsetHeight);
        })
      }, 1000)
      setTimeout(function(){
        window.close();
      }, 1500)
    }
  }

  applyPointerColor(deg) {
    if(deg > 0) {
      $('#pointer').removeClass('left-win no-win').addClass('right-win');
    } else if(deg < 0) {
      $('#pointer').removeClass('right-win no-win').addClass('left-win');
    } else {
      $('#pointer').removeClass('left-win right-win').addClass('no-win');
    }
  }
}