import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js'

export default class extends Controller {
  connect() {
    var _this = this
    if(!window.sparkQ) {
      window.sparkQ = []
    }
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var elementId = this.data.get('elementId')
      var elementChannel = window.pusher.subscribe("element_" + elementId);
      elementChannel.bind('update', function(data) {
        window.parent.resizeIframe && window.parent.resizeIframe(data.height, data.width, data.xPos, data.yPos, elementId);
        Turbolinks.visit(window.location.href);
      });
      elementChannel.bind('spark', function(data) {
        window.sparkQ.push(data)
        if(window.sparkQ.length == 1) {
          _this.playSpark()
        }
      });
    }
  }

  playSpark() {
    const data = window.sparkQ.shift()
    $('#spark_container').html('')
    if(data) {
      if(data.content_type.includes('url')) {
        window.location.href = data.url;
      } else if(data.content_type.includes('image')) {
        var img = document.createElement('img')
        img.src = data.url
        document.getElementById('spark_container').appendChild(img);
      } else if(data.content_type.includes('audio')) {
        var audio = new Audio(data.url);
        audio.play();
      }
      setTimeout(this.playSpark, data.timeout * 1000)
    }
  }
}
