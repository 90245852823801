import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ["renameOrgModal", "addMemberModal"];

  renameOrg() {
    event.preventDefault();
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.renameOrgModalTarget,
      "modal"
    );
    modalController.open();
  }

  openAddMemberModal(event) {
    event.preventDefault();
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.addMemberModalTarget,
      "modal"
    );
    modalController.open();
  }
}
