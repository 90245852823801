import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    var timer_seconds = this.data.get('seconds');
    if(window.displayTimerID) {
      clearTimeout(window.displayTimerID);
    }
    if(timer_seconds) {
      this.displayTimer(timer_seconds);
    }
  }

  disconnect() {
    if(window.displayTimerID) {
      clearTimeout(window.displayTimerID);
      window.displayTimerID = null
    }
  }

  displayTimer(seconds) {
    var _this = this
    $('.timer span').html(this.sec2human(seconds))
    seconds = seconds - 1
    if(seconds >= 0) {
      window.displayTimerID = setTimeout(function(){
        _this.displayTimer(seconds);
      }, 1000)
    } else {
      if (window.displayTimerID) {
        this.setHideAndEndTimeouts()
      }
    }
  }

  make_hide(url) {
    Rails.ajax({
      url: url,
      type: "POST",
      success: function() {
        top.Turbolinks.visit(top.location.href);
      }
    })
  }

  setHideAndEndTimeouts() {
    let url = this.data.get("callback-url")
    Rails.ajax({
      url: url,
      type: "PUT",
      success: () => {
        let hideTimer = this.data.get("hide-seconds")
        if (hideTimer) {
          let milliseconds = hideTimer * 1000
          let hide_url = this.data.get("hide-url");
          setTimeout(
            this.make_hide(hide_url)  
          , milliseconds)
        } else {
          top.Turbolinks.visit(top.location.href);
        }
      }
    })
  }

  sec2human(seconds) {
    var sec = seconds % 60;
    var min = parseInt(seconds / 60);
    if(sec.toString().length == 1) { // padding
        sec = "0" + sec;
    }
    return min + ":" + sec;
  }
}
