import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js'

export default class extends Controller {

  static targets = ['filterChatModal'];

  connect() {
    $('a.icon-expand').click(function(){
      if($('.new-layout').hasClass('layers-menu-open')) {
        $('.new-layout').removeClass('layers-menu-open');
      } else {
        $('.new-layout').addClass('layers-menu-open');
      }
      return false;
    });
    this.bindInfiniteLoad();
    this.pusher = new Pusher(process.env.PUSHER_KEY, {
      cluster: process.env.PUSHER_CLUSTER
    });
    this.projectChannel = this.pusher.subscribe("chat_messages_" + this.data.get('projectId'));
    this.elementChannel = this.pusher.subscribe("element_" + this.data.get('elementId'));
    this.projectChannel.bind('new', this.addNewChat);
    this.elementChannel.bind('add_to_queue', this.reloadChatQueue);
    this.elementChannel.bind('remove_from_queue', this.reloadChatQueue);
    this.elementChannel.bind('add_favorite', this.addFavorite)
    this.elementChannel.bind('remove_favorite', this.removeFavorite)
    $('#element_chat_queue_attributes_send_all_to_queue').change(function(){
      var element = document.getElementById('element_chat_queue_attributes_filter')
      element.checked = this.checked
    })
  }

  disconnect() {
    this.pusher.disconnect();
  }

  filterChat() {
    event.preventDefault();
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.filterChatModalTarget,
      'modal'
    );
    modalController.open();
  }

  restoreFilterWords() {
    event.preventDefault()
    $.ajax({ type: "POST", url: this.data.get('restoreFilterWordsUrl') })
  }

  submitFilterChat() {
    event.preventDefault()
    var url = this.data.get('loadUrl')
    var _this = this
    $.ajax({
      url: url,
      data: {
        event_source: $('#filter_source').val(), 
        q: $('#filter_search').val()
      }
    }).done(function(data){
      $("#chat_list").off("scroll")
      $('#chat_list').html(data)
      if($('#filter_count').length > 0) {
        $('.filter-count').show()
        $('.filter-count span').html($('#filter_count').val())
      }
      $("#chat_list").animate({ scrollTop: $("#chat_list")[0].scrollHeight}, 1)
      let modalController = _this.application.getControllerForElementAndIdentifier(
        _this.filterChatModalTarget,
        'modal'
      );
      modalController.close();
      $("#chat_list").on("scroll", _this.checkScrollChatList);
    });
  }

  clearFilterChat() {
    event.preventDefault()
    var url = this.data.get('loadUrl')
    var _this = this
    $('#filter_source').val('') 
    $('#filter_search').val('')
    $.ajax(url).done(function(data){
      $('.filter-count').hide()
      $("#chat_list").off("scroll")
      $('#chat_list').html(data)
      $("#chat_list").animate({ scrollTop: $("#chat_list")[0].scrollHeight}, 1)
      let modalController = _this.application.getControllerForElementAndIdentifier(
        _this.filterChatModalTarget,
        'modal'
      );
      modalController.close();
      $("#chat_list").on("scroll", _this.checkScrollChatList);
    });
  }

  bindInfiniteLoad = () => {
    $("#chat_list").on("scroll", this.checkScrollChatList);
    $("#favorite_list").on("scroll", this.checkScrollFavoriteList);
    $("#queue_list").on("scroll", this.checkScrollQueueList);
  }

  checkScrollChatList = () => {
    if($("#chat_list").scrollTop() > 100) {
      $("#chat_list").off("scroll");
      this.loadPreviousChatList();
    }
  }

  checkScrollFavoriteList = () => {
    var element = document.getElementById('favorite_list')
    if(element.scrollHeight - element.offsetHeight - element.scrollTop < 200) {
      $("#favorite_list").off("scroll");
      this.loadPreviousFavoriteList();
    }
  }

  checkScrollQueueList = () => {
    var element = document.getElementById('queue_list')
    if(element.scrollHeight - element.offsetHeight - element.scrollTop < 200) {
      $("#queue_list").off("scroll");
      this.loadPreviousQueueList();
    }
  }

  scrollToNewMessage = () => {
    $("#chat_list").animate({ scrollTop: 0}, 1)
    $('.new-messages').hide();
  }

  loadPreviousChatList = () => {
    var url = this.data.get('loadUrl');
    var lastId = $('#chat_list .chat-row').last().find('a').data('id');
    url = url + '?last_id=' + lastId;
    var _this = this
    $.ajax({
      url: url,
      data: {
        event_source: $('#filter_source').val(), 
        q: $('#filter_search').val()  
      }
    }).done(function(data){
      $('#chat_list').append(data)
      var newLastId = $('#chat_list .chat-row').last().find('a').data('id');
      if(lastId !== newLastId) {
        $("#chat_list").on("scroll", _this.checkScrollChatList);
      }
    });
  }

  loadPreviousFavoriteList = () => {
    var url = this.data.get('loadFavoritesUrl');
    var lastId = $('#favorite_list .chat-row').last().data('favorite-chat-id');
    url = url + '?last_id=' + lastId;
    var _this = this
    $.ajax(url).done(function(data){
      $('#favorite_list').append(data)
      var newLastId = $('#favorite_list .chat-row').last().data('favorite-chat-id');
      if(lastId !== newLastId) {
        $("#favorite_list").on("scroll", _this.checkScrollFavoriteList);
      }
    });
  }

  loadPreviousQueueList = () => {
    var url = this.data.get('loadQueueUrl');
    var lastId = $('#queue_list .chat-queue-row').last().data('queue-chat-id');
    url = url + '?last_id=' + lastId;
    var _this = this
    $.ajax(url).done(function(data){
      $('#queue_list').append(data)
      var newLastId = $('#queue_list .chat-queue-row').last().data('queue-chat-id');
      if(lastId !== newLastId) {
        $("#queue_list").on("scroll", _this.checkScrollQueueList);
      }
    });
  }

  addNewChat = (data) => {
    var template = document.querySelector('#chat_row');
    var parent = document.querySelector('#chat_list');
    var clone = template.content.cloneNode(true);
    clone.firstElementChild.dataset['id'] = data.id
    clone.querySelector('.chat-row--author-name').textContent = (data.author_display_name || data.author_name)
    clone.querySelector('.chat-row--source').classList.add(data.event_source.toLowerCase().split(' ')[0]);
    clone.querySelector('.chat-row--text').innerHTML = data.text
    clone.querySelector('a.queue').dataset['id'] = data.id
    clone.querySelector('a.fav').dataset['id'] = data.id
    clone.querySelector('a.jump-queue').dataset['id'] = data.id
    if(parent.scrollTop === 0){
      $(parent).prepend(clone);
      document.querySelector('#chat_list').scrollTo(0,0);
    } else {
      $(parent).prepend(clone);
      $('.new-messages').show();
    }
  }

  addFavorite = (data) => {
    if($('#favorite_list .chat-row[data-id=' + data.id + ']').length == 0) {
      var template = document.querySelector('#chat_row');
      var parent = document.querySelector('#favorite_list');
      var clone = template.content.cloneNode(true);
      clone.firstElementChild.dataset['id'] = data.id
      clone.querySelector('.chat-row--author-name').textContent = (data.author_display_name || data.author_name)
      clone.querySelector('.chat-row--source').classList.add(data.event_source.toLowerCase().split(' ')[0]);
      clone.querySelector('.chat-row--text').innerHTML = data.text
      clone.querySelector('a.queue').dataset['id'] = data.id
      clone.querySelector('a.fav').dataset['id'] = data.id
      clone.querySelector('a.jump-queue').dataset['id'] = data.id
      if(parent.scrollTop === 0){
        $(parent).prepend(clone);
        document.querySelector('#favorite_list').scrollTo(0,0);
      } else {
        $(parent).prepend(clone);
      }
      $('#favorite_list .chat-row[data-id=' + data.id + '] .fav').addClass('checked');
      $('#chat_list .chat-row[data-id=' + data.id + '] .fav').addClass('checked');
    }
  }

  removeFavorite = (data) => {
    $('#favorite_list .chat-row[data-id=' + data.id + ']').remove();
    $('#chat_list .chat-row[data-id=' + data.id + '] .fav').removeClass('checked');
  }

  addToQueue = () => {
    event.preventDefault()
    $(event.currentTarget).parent().addClass('added');
    var url = this.data.get('addToQueueUrl').replace('--ID--', event.currentTarget.dataset.id)
    $.ajax({ type: "POST", url: url })
  }

  removeFromQueue = () => {
    event.preventDefault()
    var url = this.data.get('removeFromQueueUrl').replace('--ID--', event.currentTarget.dataset.id)
    $.ajax({ type: "POST", url: url })
  }

  reloadChatQueue = () => {
    var url = this.data.get('loadQueueUrl')
    $.ajax(url).done(function(data) {
      $('#queue_list').html(data)
    })
  }

  jumpQueue = () => {
    event.preventDefault()
    var url = this.data.get('jumpQueueUrl').replace('--ID--', event.currentTarget.dataset.id)
    $.ajax({ type: "POST", url: url })
  }

  toggleFavorite = () => {
    event.preventDefault()
    var url = this.data.get('addToFavoriteUrl').replace('--ID--', event.currentTarget.dataset.id)
    if($(event.currentTarget).hasClass('checked')) {
      url = this.data.get('removeFromFavoriteUrl').replace('--ID--', event.currentTarget.dataset.id)
      $(event.currentTarget).removeClass('checked')
      $('#favorite_list .chat-row[data-id='+event.currentTarget.dataset.id+']').remove()
      $('#chat_list .chat-row[data-id='+event.currentTarget.dataset.id+'] .fav').removeClass('checked')
    } else {
      $(event.currentTarget).addClass('checked')
      $(event.currentTarget).parent().clone().prependTo("#favorite_list");
    }
    $.ajax({ type: "POST", url: url })
  }
}
