import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  open() {
    this.element.classList.add('modal-open');
    $('.modal-overlay').show();
  }

  close() {
    this.element.classList.remove('modal-open');
    $('.modal-overlay').hide();
    event.preventDefault();
  }
}
