import { Controller } from '@hotwired/stimulus';
import FontPicker from "font-picker";
export default class extends Controller {
  connect() {
    const fontPicker = new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.data.get('name'),
      {
        variants: ['regular', '700'],
        limit: 1
      },
    );
  }
}