import { Controller } from '@hotwired/stimulus';
import FontPicker from "font-picker";
import Rails from "@rails/ujs";

export default class extends Controller {
  
  static targets = [ "element" ]

  connect() {
    const fontPicker = new FontPicker(
      process.env.GOOGLE_FONTS_API_KEY,
      this.elementTarget.value,
      {
        variants: ['regular', '700'],
        limit: 100,
        pickerId: $(this.elementTarget).prev().attr('id').replace('font-picker-', '')
      },
      this.onChange
    );
  }

  onChange = (font) => {
    $(this.elementTarget).val(font.family);
    if(this.data.get('save') != 'false') {
      Rails.fire(this.elementTarget.form, 'submit');
    }
  }
}
