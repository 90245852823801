import { Controller } from "@hotwired/stimulus";
import Pickr from "@simonwep/pickr";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["colorPickerInput"];
  static values = {
    disable: { type: String, default: "false" },
  };

  connect() {
    if (this.data.get("disableValue") != "true") {
      this.initColorPicker();
    }
  }

  initColorPicker() {
    let pickr = Pickr.create({
      el: "#" + this.colorPickerInputTarget.id,
      theme: "nano",
      default: this.colorPickerInputTarget.value,
      comparison: true,
      useAsButton: true,
      components: {
        preview: true,
        opacity: true,
        hue: true,
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: true,
        },
      },
    });
    pickr.on("save", (color, instance) => {
      let ele = instance.getRoot().button;
      ele.value = color.toHEXA().toString();
      instance.hide();
      Rails.fire(ele.form, "submit");
    });
  }
}
