import { Controller } from '@hotwired/stimulus';
require('jquery.growl');
import 'jquery.growl/stylesheets/jquery.growl.css';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['newLayerModal', 'newElementModal', 'assetsModal', 'copyElementModal'];

  connect() {
    $.each($('#flash div'), function( key, value ) {
      var element = $(value)
      var style = element.attr('class')
      switch(style) {
        case 'alert':
          style = 'error'
          break;
      }
      $.growl({
        location: 'tc',
        title: '',
        message: element.text(),
        style: style,
        duration: 10000
      })
    });
  }
}
