import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js'
require("jquery-ui/ui/effects/effect-slide");

export default class extends Controller {
  connect() {
    var _this = this;
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var elementId = this.data.get('elementId')
      var elementChannel = window.pusher.subscribe("element_" + elementId);
      elementChannel.bind('update', function(data) {
        window.parent.resizeIframe && window.parent.resizeIframe(data.height, data.width, data.xPos, data.yPos, elementId);
        if(data.changes.length > 0) {
          Turbolinks.visit(window.location.href);
        }
      });
      elementChannel.bind('start_quiz', function() {
        _this.startCountdown();
      });
      elementChannel.bind('player_ready', function(data) {
        Turbolinks.visit(window.location.href);
      })
      elementChannel.bind('next_question', function(data) {
        $('.question-container').addClass('with-mark');
        if(data.show_results) {
          _this.load_results();
          if(data.load_next_question) {
            setTimeout(function(){
              _this.load_next_question();  
            }, 8000)
          }
        } else {
          if(data.load_next_question) {
            _this.load_next_question();
          }
        }
      });
    }
  }

  load_next_question() {
    var url = this.data.get('loadQuestionUrl')
    $.ajax({ url: url }).done(function(data){
      var a = new Date().valueOf()
      $('.trivia_container').html(data);
      $(".trivia_container .question").show("slide", { direction: "left" }, 2000);
      setTimeout(function(){
        $(".trivia_container .options").show("slide", { direction: "left" }, 2000);
        var b = new Date().valueOf()
      }, 3000)
    });
  }

  load_results() {
    var url = this.data.get('loadResultsUrl')
    $.ajax({ url: url }).done(function(data){
      $('.trivia_container').html(data);
    });
  }

  startCountdown() {
    var _this = this;
    $('.trivia_container').html("");
    $('.trivia_container').append("<div class='countdown'>3</div>");
    setTimeout(function(){
      $('.countdown').html("2");
    }, 1000)
    setTimeout(function(){
      $('.countdown').html("1");
    }, 2000)
    setTimeout(function(){
      _this.load_next_question();
    }, 3000)
  }
}
