import { Controller } from '@hotwired/stimulus';
require("jquery-ui/ui/widgets/sortable");

export default class extends Controller {
  connect() {
    const _this = this;
    $(_this.element).sortable({
      stop: function() {
        var order = {}
        $.each($(_this.element).find('li'), function( key, value ) {
          var element = $(value)
          order[element.data('id')] = { list_order: (key + 1) }
        });
        $.ajax({
          type: 'POST',
          url: _this.data.get('url'),
          data: { items: order }
        })
      }
    });
    $(_this.element).disableSelection();
    $('#layers-list').off('click', '.active .live-icon');
    $('#layers-list').on('click', '.active .live-icon', function(){
      var ele = $(this)
      var isLive = ele.hasClass('live');
      $.ajax({
        type: 'POST',
        url: ele.data('url'),
        data: { live: !isLive }
      }).done(function(data){
        isLive = !data.live;
        $('#layers-list .live-icon').removeClass('live');
        if(isLive) {
          ele.removeClass('live');
        } else {
          ele.addClass('live');
        }  
      });
      
    })
  }
}
