import { Controller } from '@hotwired/stimulus';
require('jquery.growl');
import 'jquery.growl/stylesheets/jquery.growl.css';
import Rails from "@rails/ujs";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
require("jquery-ui/ui/widgets/selectmenu");
import 'jquery-ui/themes/base/selectmenu.css';

export default class extends Controller {
  static targets = ['newLayerModal', 'newElementModal', 'assetsModal', 'copyElementModal', 'infoPopupModal'];

  connect() {
    tippy('[data-tippy-content]', { theme: 'light' });

    this.flashNotifications();
    //show help popup for first default scene
    this.showFirstSceneHelp();
  }

  mouseOver(event) {
    let popup_menu = event.currentTarget.querySelector('.popup-menu')

    if (event.currentTarget.dataset.top) {
      popup_menu.style.top = `${event.currentTarget.offsetParent.offsetParent.offsetTop + event.currentTarget.offsetParent.offsetTop + parseInt(event.currentTarget.dataset.top)}px`
      popup_menu.style.left = `${event.currentTarget.offsetLeft}px`
    }
    popup_menu.style.display = 'block'
  }

  mouseOut(event) {
    event.currentTarget.querySelector('.popup-menu').style.display = 'none'
  }

  // NOTE: Element::Vibe
  // submitElementForm(event) {
  //   Rails.fire(event.currentTarget.closest('form'), 'submit');
  // }

  updateFormInputs(event) {
    let modal_new_element = event.currentTarget.closest('.modal-new-element')

    if (['Element::Chat', 'Element::ChatPro'].includes(event.currentTarget.value)) {
      modal_new_element.querySelector('.for-chat').style.display = 'block'
      modal_new_element.querySelector('.for-not-chat').style.display = 'none'
      modal_new_element.querySelector('.for-chat #element_name').name = 'element[name]'
      modal_new_element.querySelector('.for-not-chat #element_name').name = ''
    } else {
      modal_new_element.querySelector('.for-chat').style.display = 'none'
      modal_new_element.querySelector('.for-not-chat').style.display = 'block'
      modal_new_element.querySelector('.for-chat #element_name').name = ''
      modal_new_element.querySelector('.for-not-chat #element_name').name = 'element[name]'
    }
  }

  cleanElementValue(event) {
    event.currentTarget.offsetParent.querySelector(`#${event.currentTarget.dataset.idRef}`).value = ''
  }

  flashNotifications() {
    document.querySelectorAll('#flash div').forEach( (element) => {
      let style = element.classList.value
      if(style == 'alert') { style = 'error'}

      $.growl({
        title: '',
        message: element.innerText,
        style: style,
        duration: 10000
      })
    });
  }

  showFirstSceneHelp(){
    if($('.layer-container').data('show-help')){
      let _this = this;
      setTimeout(function(){
        let modalController = _this.application.getControllerForElementAndIdentifier(
          _this.infoPopupModalTarget,
          'modal'
        );
        modalController.open();
      }, 600);
    }
  }

  copyElement() {
    event.preventDefault();
    if(event.currentTarget.dataset.type == 'Element::Chat') {
      $('.modal-copy-element .clone-data-source').show();
    } else {
      $('.modal-copy-element .clone-data-source').hide();
    }
    $('.modal-copy-element #layer_id').val(event.currentTarget.dataset.layer)
    $('.modal-copy-element form').attr('action', event.currentTarget.dataset.url)
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.copyElementModalTarget,
        'modal'
    );
    modalController.open();
  }

  newElement() {
    event.preventDefault();
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.newElementModalTarget,
        'modal'
    );
    modalController.open();
  }

  newLayer() {
    event.preventDefault()
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.newLayerModalTarget,
        'modal'
    );
    modalController.open();
  }

  selectProject(event) {
    if($(event.target).attr('id') == 'create_project'){
      Rails.fire($(event.target).parent()[0], 'submit');
    }else{
      if($(event.target).attr('id') != 'delete_project' && $(event.target).attr('id') != 'copy_project') {
        Turbolinks.visit(event.currentTarget.dataset.href);
      }
    }
  }

  formPrepend() {
    event.preventDefault()
    var obj = $($(event.currentTarget).attr("data-form-prepend"));
    obj.find("input, select, textarea").each(function() {
      $(this).attr("name", function() {
        return $(this)
          .attr("name")
          .replace("new_record", new Date().getTime());
      });
    });
    var child = obj.children()[0]
    var type = child.dataset.type;
    var count = $('[data-type="'+type+'"]').length + 1;
    child.dataset.index = count;
    obj.find("div.order").each(function() {
      $(this).html($(this).html().replace("new_record", count));
    });
    obj.find("input.order").each(function() {
      $(this).val(count);
    });
    obj.insertBefore(event.currentTarget);
    $('#reload').val('yes');
    Rails.fire($(event.currentTarget).parents('form')[0], 'submit');
  }

  openAssetsModal() {
    event.preventDefault();
    let modalController = this.application.getControllerForElementAndIdentifier(
        this.assetsModalTarget,
        'modal'
    );
    modalController.open();
    $(this.assetsModalTarget).data('form-remote', $(event.currentTarget).data('form-remote'));
    $(this.assetsModalTarget).data('target-name', $(event.currentTarget).data('target-name'));
    $(this.assetsModalTarget).data('target-id', $(event.currentTarget).data('target-id'));
    $(this.assetsModalTarget).data('show-gating-images', $(event.currentTarget).data('show-gating-images'));
    this.loadAssetsModal(modalController);
  }

  loadAssetsModal(modalController) {
    var _this = this
    var showGatedImages = $(_this.assetsModalTarget).data('show-gating-images');
    var assetURL = this.data.get('loadAssetsUrl');
    if(showGatedImages && showGatedImages===true){
      assetURL = `${this.data.get('loadAssetsUrl')}?gated_images=true`
    }

    $.ajax({
      url: assetURL
    }).done(function(data) {
      $('.modal-project-assets .body').html(data);
      $('.asset-item-with-background').click(function(){
        const input_file = $('#' + $(_this.assetsModalTarget).data('target-id'));
        if(input_file.parent().find('[type="hidden"][name="' + $(_this.assetsModalTarget).data('target-name') +'"]').length == 0) {
          $("<input type='hidden' name='"+$(_this.assetsModalTarget).data('target-name')+"'>").insertBefore(input_file);
        }
        input_file.parent().find('[type="hidden"][name="' + $(_this.assetsModalTarget).data('target-name') +'"]').val($(this).data('signed-id'));
        if($(_this.assetsModalTarget).data('form-remote') == true) {
          $('#reload').val('yes');
        }
        // if($(_this.assetsModalTarget).data('form-remote') == true) {
        //   $(input_file[0].form).one('ajax:success', function(){
        //     $('.element-items .element-item.active')[0].click();
        //   })
        // }
        Rails.fire(input_file[0].form, 'submit');
        modalController.close();
      });
      $('.btn-asset-delete').click(function(){
        const _this = this;
        const confirm = window.confirm("Are you sure?")
        if(confirm) {
          $.ajax({
            url: $(_this).data('url'),
            method: 'DELETE'
          }).done(function(data) {
            $(_this).parent().parent().remove();
          });
        }
        return false;
      })
      $('#project_asset_file').on('direct-upload:start', (event) => {
        const { id, file } = event.detail
        const meter = $(event.currentTarget).parent().find('.meter')
        meter.css('display', 'block')
      });
      $('#project_asset_file').on('direct-upload:progress', (event) => {
        const { id, progress, file } = event.detail
        const meter = $(event.currentTarget).parent().find('.meter')
        meter.find('.meter-width').css('width', progress + '%')
      });
      $('#project_asset_file').on('direct-upload:end', (event) => {
        const { id, file } = event.detail
        const meter = $(event.currentTarget).parent().find('.meter')
      });
      $(".asset-add-item form").on("ajax:success", (event) => {
        _this.loadAssetsModal(modalController);
      });
    })
  }

  assetSave(event) {
    Rails.fire(event.currentTarget, 'submit');
  }

  updateEventState(){
    let target = event.currentTarget;
    let currentEventState = target.dataset.state || 'pre_event';
    let url = event.currentTarget.dataset.url;
    let nextState = '';
    if(currentEventState == 'pre_event'){
      nextState = 'live';
    }else if(currentEventState == 'live'){
      nextState = 'post-event';
    }

    if(nextState.length > 0){
      $.ajax({
        url: url,
        type: 'POST',
        data: { event_status: currentEventState }
      }).then(function(data){
        if(data.success){
          target.dataset.state = data.event_status;
          if(data.event_status == 'live'){
            $(target).addClass('live');
            $(target).text('End Event');
          }else if(data.event_status == 'post_event'){
            $(target).parent().hide();
          }
        }else{
          alert('Error while updating event, try again!');
        }
      })
    }
  }

  toggleProjectListView(){
    let target = $(event.currentTarget)
    if(target.hasClass('grid')){
      target.removeClass('grid');
      target.attr('title', 'List View');
      $('.dashboard.index .grid-view').show();
      $('.dashboard.index .list-view').hide();
    }else{
      target.addClass('grid');
      target.attr('title', 'Grid View');
      $('.dashboard.index .grid-view').hide();
      $('.dashboard.index .list-view').show();
    }
  }
  toggleProjectSort(){
    $(event.currentTarget).find('.sort-menu').toggle();
  }
}
