import { Controller } from '@hotwired/stimulus';
import Pusher from 'pusher-js'

export default class extends Controller {

  connect() {
    const _this = this
    window.resizeIframe = function(height, width, xPos, yPos, uuid) {
      var element = document.getElementById("iframe-holder-" + uuid);
      if(element) {
        element.style.height = height + "px";
        element.style.width = width + "px";
        element.style.top = yPos + "px";
        element.style.left = xPos + "px";
      }
    }
    if(!window.pusher) {
      window.pusher = new Pusher(process.env.PUSHER_KEY, {
        cluster: process.env.PUSHER_CLUSTER
      });
      var projectChannel = window.pusher.subscribe("project_" + this.data.get('projectId'));
      projectChannel.bind('live', function(data) {
        var liveFrame = $('iframe.live')
        if(liveFrame[0]) {
          liveFrame[0].onload = function() {}
          liveFrame.css({
            opacity: 0,
            visibility: 'hidden'
          })
          liveFrame.removeClass('live');  
          setTimeout(function(){
            liveFrame.attr( 'src', function ( i, val ) { return val; });  
          }, 2000)
        }
        if(data.live) {
          var frameID = '#iframe-layer-' + data.id;
          $(frameID)[0].onload = function() {
            $(frameID).css({
              opacity: 1,
              visibility: 'visible'
            })
          }
          $(frameID).addClass('live');  
          $(frameID).attr( 'src', function ( i, val ) { return val; });  
        }
      });
      projectChannel.bind('add_scene', function(data) {
        Turbolinks.visit(window.location.href);
      })
      projectChannel.bind('remove_scene', function(data) {
        Turbolinks.visit(window.location.href);
      })
    }
  }
}
