import { Controller } from "@hotwired/stimulus";
require("zebra_datepicker");
import 'zebra_datepicker/dist/css/default/zebra_datepicker.min.css';
import moment from 'moment';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['dateControl'];
  static values = { startDate: String };

  connect() {
    const dateInputControl = this.dateControlTarget;
    let start_at = this.data.get('startDateValue');
    let start_at_date;
    if (start_at) {
      start_at = moment.utc(start_at).local();
      start_at_date = start_at.toDate();
    } else {
      start_at = false
    }
    $(dateInputControl).Zebra_DatePicker({
      start_date: start_at_date,
      enabled_ampm: ['am', 'pm'],
      format: 'm-d-Y h:i:s a',
      default_position: 'below',
      show_clear_date: false,
      onClose: function () {
        Rails.fire(dateInputControl.form, 'submit');
      }
    });
    if (start_at) {
      dateInputControl.value = start_at.format('MM-DD-YYYY hh:mm:ss a');
    }
  }
}
