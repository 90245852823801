import { Controller } from "@hotwired/stimulus";
import Pusher from 'pusher-js'

export default class extends Controller {

  connect() {
    $('.custom-checkbox input').on('change', function() {
      $(this).parent().parent().parent().find('.custom-checkbox input').not(this).prop('checked', false);
    });
    this.pusher = new Pusher(process.env.PUSHER_KEY, {
      cluster: process.env.PUSHER_CLUSTER
    });
    this.elementChannel = this.pusher.subscribe("element_" + this.data.get('elementId'));
    this.elementChannel.bind('start_quiz', function() {
      $('.state-created').hide();
      $('.state-running').show();
    });
    this.elementChannel.bind('finish_quiz', function() {
      $('.state-created').hide();
      $('.state-running').hide();
      $('.state-finished').show();
    });
  }
}
