import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    $(this.element).find('.tab-headers a').click(function(){
      if($(this).data('target')) {
        $(this).parent().find('a').removeClass('active');
        $(this).addClass('active');
        $(this).parent().parent().find('.tabs > div').removeClass('active');
        $(this).parent().parent().find($(this).data('target')).addClass('active');
        history.pushState(null, '', "?tab="+ $(this).data('href'));
        return false;  
      }
    })
  }
}